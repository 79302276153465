.footer-container {
  width: 90%;
}

.menu-footer-navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: rem(8);
  pointer-events: auto;

  @include breakpoint(md) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 85%;
    margin: auto;
  }

  .footer-link {
    height: rem(25);
    margin-right: rem(26);
    color: white;
    font-size: rem(18);
    font-family: $base-font-family;
    @include breakpoint(md) {
      margin-bottom: rem(14);
    }
  }

  .popUp-button {
    position: relative;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
      outline: none;
    }

    &:last-of-type {
      &:hover,
      &:focus {
        text-decoration: none;
        outline: none;
      }
    }
  }

  .share {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .popup {
    position: absolute;
    top: rem(-55);
    left: -30%;
    display: none;
    align-items: center;
    justify-content: space-between;
    width: rem(148);
    height: rem(42);
    padding: rem(0) rem(19) rem(0) rem(19);
    background-color: black;
    border-radius: 10px;

    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      width: rem(18);
      height: rem(13);
      background-color: black;
      transform: translateX(-50%);
      content: '';
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }

    &.show {
      display: flex;
    }

    .popup-link {
      height: rem(24);
    }

    /* prettier-ignore */
    .linkedIn-icon {
      display: inline-block;
      width: rem(24);
      height: rem(24);
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22"><path fill="grey" d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0ZM7.8,16.63H5.12V8.57H7.8ZM6.46,7.47h0a1.53,1.53,0,0,1-1.08-.41,1.34,1.34,0,0,1-.4-1,1.36,1.36,0,0,1,.41-1,1.56,1.56,0,0,1,1.1-.4,1.53,1.53,0,0,1,1.09.4,1.4,1.4,0,0,1,.41,1,1.35,1.35,0,0,1-.41,1A1.57,1.57,0,0,1,6.46,7.47Zm11,9.16H14.78V12.32c0-1.09-.38-1.83-1.35-1.83a1.48,1.48,0,0,0-1.38,1,1.92,1.92,0,0,0-.09.66v4.5H9.29s0-7.3,0-8.06H12V9.71a2.68,2.68,0,0,1,2.42-1.33c1.76,0,3.08,1.15,3.08,3.63Z"/></svg>')
        center center no-repeat;

      /* prettier-ignore */
      &:hover {
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22"><path fill="white" d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0ZM7.8,16.63H5.12V8.57H7.8ZM6.46,7.47h0a1.53,1.53,0,0,1-1.08-.41,1.34,1.34,0,0,1-.4-1,1.36,1.36,0,0,1,.41-1,1.56,1.56,0,0,1,1.1-.4,1.53,1.53,0,0,1,1.09.4,1.4,1.4,0,0,1,.41,1,1.35,1.35,0,0,1-.41,1A1.57,1.57,0,0,1,6.46,7.47Zm11,9.16H14.78V12.32c0-1.09-.38-1.83-1.35-1.83a1.48,1.48,0,0,0-1.38,1,1.92,1.92,0,0,0-.09.66v4.5H9.29s0-7.3,0-8.06H12V9.71a2.68,2.68,0,0,1,2.42-1.33c1.76,0,3.08,1.15,3.08,3.63Z"/></svg>')
          center center no-repeat;
      }
    }

    /* prettier-ignore */
    .twitter-icon {
      display: inline-block;
      width: rem(24);
      height: rem(24);
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22"><path fill="grey" d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm5.022,8.577c.005.108.008.217.008.326a7.136,7.136,0,0,1-7.184,7.184h0a7.139,7.139,0,0,1-3.87-1.135,5,5,0,0,0,.6.035,5.067,5.067,0,0,0,3.136-1.08,2.53,2.53,0,0,1-2.359-1.754,2.567,2.567,0,0,0,.475.046A2.524,2.524,0,0,0,7.5,12.11,2.526,2.526,0,0,1,5.47,9.635V9.6a2.517,2.517,0,0,0,1.144.315,2.526,2.526,0,0,1-.782-3.37,7.173,7.173,0,0,0,5.205,2.638,2.487,2.487,0,0,1-.066-.576,2.526,2.526,0,0,1,4.368-1.727,5.059,5.059,0,0,0,1.6-.613,2.536,2.536,0,0,1-1.11,1.4,5.04,5.04,0,0,0,1.45-.4A5.154,5.154,0,0,1,16.022,8.577Z"/></svg>')
        center center no-repeat;

      /* prettier-ignore */
      &:hover {
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22"><path fill="white" d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm5.022,8.577c.005.108.008.217.008.326a7.136,7.136,0,0,1-7.184,7.184h0a7.139,7.139,0,0,1-3.87-1.135,5,5,0,0,0,.6.035,5.067,5.067,0,0,0,3.136-1.08,2.53,2.53,0,0,1-2.359-1.754,2.567,2.567,0,0,0,.475.046A2.524,2.524,0,0,0,7.5,12.11,2.526,2.526,0,0,1,5.47,9.635V9.6a2.517,2.517,0,0,0,1.144.315,2.526,2.526,0,0,1-.782-3.37,7.173,7.173,0,0,0,5.205,2.638,2.487,2.487,0,0,1-.066-.576,2.526,2.526,0,0,1,4.368-1.727,5.059,5.059,0,0,0,1.6-.613,2.536,2.536,0,0,1-1.11,1.4,5.04,5.04,0,0,0,1.45-.4A5.154,5.154,0,0,1,16.022,8.577Z"/></svg>')
          center center no-repeat;
      }
    }

    /* prettier-ignore */
    .facebook-icon {
      display: inline-block;
      width: rem(24);
      height: rem(24);
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22"><circle cx="11" cy="11" r="11" fill="black"/><path d="M22,11A11,11,0,1,0,11,22h.2V13.4H8.8V10.7h2.4v-2c0-2.4,1.4-3.7,3.5-3.7h2.1V7.6H15.4c-1.1,0-1.4.5-1.4,1.3v1.8h2.8l-.4,2.7H14v8.2A11.1,11.1,0,0,0,22,11Z" fill="grey"/></svg>') center center no-repeat;

      /* prettier-ignore */
      &:hover {
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 22 22"><circle cx="11" cy="11" r="11" fill="black"/><path d="M22,11A11,11,0,1,0,11,22h.2V13.4H8.8V10.7h2.4v-2c0-2.4,1.4-3.7,3.5-3.7h2.1V7.6H15.4c-1.1,0-1.4.5-1.4,1.3v1.8h2.8l-.4,2.7H14v8.2A11.1,11.1,0,0,0,22,11Z" fill="white"/></svg>') bottom center no-repeat;
      }
    }

    /* prettier-ignore */
    .resources-icon {
      display: inline-block;
      width: rem(24);
      height: rem(24);
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 14 11.6"><path fill="grey" d="M7,11.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.2-0.1-0.2-0.2-0.2c-0.1-0.1-0.4-0.1-0.7-0.1H0.7C0.3,10.7,0,10.4,0,10V0.7 C0,0.3,0.3,0,0.7,0h4.8c0.6,0,1.1,0.1,1.5,0.4c0,0,0,0,0.1,0c0,0,0,0,0.1,0C7.4,0.1,7.9,0,8.5,0h4.8C13.7,0,14,0.3,14,0.7V10 c0,0.4-0.3,0.7-0.7,0.7H8.5c-0.3,0-0.6,0-0.7,0.1c-0.1,0.1-0.1,0.1-0.2,0.2C7.6,11.3,7.3,11.6,7,11.6z M8.5,1.3 C8.2,1.3,8,1.4,7.8,1.5C7.7,1.6,7.7,1.7,7.7,1.9v7.6c0.3-0.1,0.6-0.1,0.8-0.1h4.2v-8H8.5z M1.3,9.4h4.2c0.2,0,0.5,0,0.9,0.1V1.9 c0-0.2-0.1-0.3-0.2-0.4C6,1.4,5.8,1.3,5.5,1.3H1.3L1.3,9.4z"/></svg>') center center no-repeat;

      /* prettier-ignore */
      &:hover {
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 14 11.6"><path fill="grey" d="M7,11.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.2-0.1-0.2-0.2-0.2c-0.1-0.1-0.4-0.1-0.7-0.1H0.7C0.3,10.7,0,10.4,0,10V0.7 C0,0.3,0.3,0,0.7,0h4.8c0.6,0,1.1,0.1,1.5,0.4c0,0,0,0,0.1,0c0,0,0,0,0.1,0C7.4,0.1,7.9,0,8.5,0h4.8C13.7,0,14,0.3,14,0.7V10 c0,0.4-0.3,0.7-0.7,0.7H8.5c-0.3,0-0.6,0-0.7,0.1c-0.1,0.1-0.1,0.1-0.2,0.2C7.6,11.3,7.3,11.6,7,11.6z M8.5,1.3 C8.2,1.3,8,1.4,7.8,1.5C7.7,1.6,7.7,1.7,7.7,1.9v7.6c0.3-0.1,0.6-0.1,0.8-0.1h4.2v-8H8.5z M1.3,9.4h4.2c0.2,0,0.5,0,0.9,0.1V1.9 c0-0.2-0.1-0.3-0.2-0.4C6,1.4,5.8,1.3,5.5,1.3H1.3L1.3,9.4z"/></svg>') center center no-repeat;
      }
    }
  }
}

.legal-container {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: rem(21);

  .user-controls {
    position: relative;
    display: none;
    width: auto;
    .controller {
      height: fit-content;
      margin: 0;
    }

    @include breakpoint(md) {
      display: flex;
    }
  }

  .bottom-logo {
    @include breakpoint(md) {
      display: none;
    }
  }

  @include breakpoint(sm) {
    display: flex;
  }

  a {
    margin-bottom: rem(8);
    color: #9b9da6;
    font-weight: normal;
    font-size: rem(12);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;

    &:nth-of-type(2) {
      margin-left: rem(15);
    }
  }
  p {
    color: #9b9da6;
    font-weight: normal;
    font-size: rem(10);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
