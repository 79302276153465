@mixin breakpoint($class) {
  @if $class == xxs {
    @media (max-width: $size-xxs) {
      @content;
    }
  } @else if $class == xs {
    @media (max-width: $size-xs) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: $size-sm) {
      @content;
    }
  } @else if $class == md {
    @media (max-width: $size-md) {
      @content;
    }
  } @else if $class == lg {
    @media (max-width: $size-lg) {
      @content;
    }
  } @else if $class == xl {
    @media (max-width: $size-xl) {
      @content;
    }
  } @else if $class == xxl {
    @media (max-width: $size-xxl) {
      @content;
    }
  } @else {
    @warn 'Breakpoint mixin supports: xxs, xs, sm, md, lg, xl, xxl';
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
