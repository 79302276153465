.module {
  > .module-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    font-size: $font-size-lg;
    font-family: 'Nanum Myeongjo', serif;
  }

  &.hide {
    display: none;
  }
}

.visibility-hidden {
  position: absolute;
  width: rem(1);
  height: rem(1);
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}
