.account-menu {
  color: white;

  .account-linked-accounts,
  .account-completion,
  .account-share {
    p {
      margin: 0;
      font-size: rem(13);
    }
  }

  .account-linked-accounts p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: rem(270);

    &:first-of-type {
      margin-bottom: rem(10);
    }
  }

  .account-header {
    h2 {
      display: inline-block;
    }

    .log-btn {
      position: relative;
      top: rem(-4);
      width: rem(68);
      height: rem(32);
      margin-left: rem(19.5);
      color: white;
      font-size: rem(12);
      line-height: rem(16);
      background-color: black;
      border: 1px solid grey;
      border-radius: rem(20);
    }

    .log-btn:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .log-in-btn {
      padding: rem(7) rem(11) rem(9) rem(12);
    }

    @include breakpoint(lg) {
      margin-bottom: rem(40);
    }
  }

  .account-email {
    margin-bottom: rem(44);

    p {
      font-size: rem(24);
    }

    @include breakpoint(lg) {
      margin-bottom: rem(30);

      p {
        font-size: rem(18);
      }
    }
  }

  .account-settings {
    margin-bottom: rem(44);

    ul {
      display: flex;
      align-items: center;
      justify-content: row;
      margin: 0;
      list-style: none;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: row;
      margin: 0;
    }

    input {
      margin-right: rem(10);
      padding: none;
    }
  }

  .account-linked-accounts {
    margin-bottom: rem(41);

    .google-btn,
    .facebook-btn {
      display: inline;
      width: rem(100);
      height: rem(28);
      margin-bottom: 0;
      margin-left: rem(20);
      color: black;
      line-height: rem(20);
      background-color: white;
    }

    .icon-google,
    .icon-facebook {
      width: rem(17);
      height: rem(17);
      margin-right: rem(6);
      vertical-align: top;
    }

    @include breakpoint(lg) {
      margin-bottom: rem(30);
    }
  }

  .account-completion {
    margin-bottom: rem(35);

    .needs-complete {
      color: #9b9da6;
    }

    .name-label {
      display: block;
      margin-bottom: rem(6);
    }
    .name-input {
      display: block;
      width: rem(200);
      margin-bottom: rem(24);
    }
    .submit-name-button {
      max-width: rem(100);
      margin-top: 0;
      color: #53545c;
    }

    button {
      color: white;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      &.disabled {
        color: grey;
        &:hover {
          text-decoration: none;
          cursor: not-allowed;
        }
      }
    }

    @include breakpoint(lg) {
      margin-bottom: rem(30);
    }
  }

  .account-share {
    margin-bottom: rem(35);

    @include breakpoint(lg) {
      margin-bottom: rem(24);
    }
  }
}
