.tool-tip-popup {
  position: fixed;
  z-index: 999;
  width: rem(262);
  height: rem(113);
  color: black;
  background: white;
  border-radius: rem(5);
  filter: drop-shadow(rem(2) rem(2) rem(2) rgba(128, 131, 142, 0.15));

  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: initial;
  }

  .first-cta {
    text-decoration: underline;
  }

  .first-cta:hover {
    cursor: pointer;
  }

  .tool-tip-body {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: rem(16);
    color: black;
    font-size: rem(13);

    .cta {
      display: flex;
      flex-direction: row;
      align-items: auto;
      justify-content: space-between;
      width: 100%;
      margin-top: rem(14);
    }

    .cta-btn {
      display: flex;
      justify-content: center;
      width: rem(67);
      height: rem(27);
      margin: 0;
      padding: rem(4) 0;
      background: black;
      border: none;
      box-shadow: (rem(2) rem(2) rem(7) 0 $bluey-grey);

      &:hover {
        background: #232324;
      }

      .next-arrow {
        display: flex;
        align-items: center;
        width: rem(15);
        height: rem(15);
        margin-top: rem(2);
        margin-right: 0;
        margin-left: rem(7);
        padding-left: rem(1);
        font-size: rem(12);
        background-color: #3d3d3d;
        border: 1px solid #3d3d3d;
        border-radius: rem(20);
      }
    }
  }

  &::after {
    position: absolute;
    bottom: rem(0);
    display: block;
    width: 0;
    height: 0;
    border: rem(9) solid transparent;
    border-top-color: white;
    border-bottom: 0;
    transform: translate(-50%, 100%);
    content: '';
  }
}

.browser-onboarding-0 {
  position: absolute;
  bottom: rem(70);
  left: 50%;
  z-index: 999;
  transform: translate(-50%, 0%);
}

.browser-onboarding-0::after {
  left: 50%;
}

.browser-onboarding-1 {
  position: absolute;
  bottom: rem(110);
  left: calc(100% - rem(55));
  z-index: 999;
  transform: translate(-100%, 0%);

  @include breakpoint(lg) {
    left: calc(100% - rem(35));
  }
}

.browser-onboarding-1::after {
  left: 84%;
}

.module-onboarding-0 {
  top: 50%;
  left: 2%;
  transform: translate(0%, -146%);
  @include breakpoint(sm) {
    display: none;
    // top: 70%;
    // left: 53%;
  }

  @include breakpoint(md) {
    display: none;
  }
}

.module-onboarding-0::after {
  left: rem(30);
}

.module-onboarding-1 {
  bottom: rem(70);
  left: 50%;
  z-index: 999;
  display: none;
  transform: translate(-50%, 0%);
}

.module-onboarding-1::after {
  left: 50%;
}

$offsetVal: rem(38);

@for $i from 0 through 5 {
  .tool-tip-popup.module-onboarding-0.y-offset-#{$i} {
    top: calc(50% - #{$offsetVal} * #{$i});
  }

  .tool-tip-popup.module-onboarding-0.y-offset-#{$i}-half {
    top: calc(50% - (#{$offsetVal} * #{$i}) - (#{$offsetVal} * 0.5));
  }
}
