.styleguide-h1 {
  padding: 5% 0 2.5% 0;
  text-align: center;
}

.styleguide-container {
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  padding: 5%;

  @media (min-width: $size-md) {
    grid-template-columns: repeat(2, 2fr);
  }
}

.styleguide-heading,
.styleguide-container span {
  color: #aeaeae;
  font-weight: 400;
  font-size: rem(20);
  text-transform: uppercase;
}

.styleguide-intro {
  display: flex;
  justify-content: space-evenly;
}

.styleguide-container span {
  font-size: rem(12);
}

.styleguide-core-colors,
.styleguide-lists,
.styleguide-typography,
.styleguide-navigation,
.styleguide-forms,
.styleguide-pagination,
.styleguide-buttons {
  padding: 5% 0;

  span {
    display: block;
    padding: 1% 0;
  }
}

// hide for light theme
.hide-for-dark {
  display: none;
}
.theme-light {
  .hide-for-light {
    display: none;
  }
  // hide for dark theme
  .hide-for-dark {
    display: block;
  }
}

.styleguide-link-icons {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-around;
}

.styleguide-core-colors {
  display: grid;
  grid-gap: 1%;
  grid-template-rows: max-content max-content;
  grid-template-columns: repeat(2, 1fr);
}

.core-colors {
  width: rem(50);
  height: rem(50);
  border-radius: 50%;

  &-primary {
    @extend .core-colors;

    background: $black;
    border: rem(1) solid rgba($color: #fff, $alpha: 0.5);
  }

  &-secondary {
    @extend .core-colors;

    background: $white;
    border: rem(1) solid rgba($color: #000, $alpha: 0.1);
  }

  &-primary-lighter {
    @extend .core-colors;

    background: $black-hover;
    border: rem(1) solid rgba($color: #fff, $alpha: 0.5);
  }

  &-secondary-lighter {
    @extend .core-colors;

    background: $white-hover;
    border: rem(1) solid rgba($color: #000, $alpha: 0.1);
  }

  &-dark-purple {
    @extend .core-colors;

    background: $dark-purple;
    border: rem(1) solid rgba($color: #fff, $alpha: 0.5);
  }
}

.gray-colors {
  width: rem(50);
  height: rem(50);
  border-radius: 50%;

  &-dark-gray {
    @extend .gray-colors;

    background: $dark-gray;
  }

  &-steel {
    @extend .gray-colors;

    background: $steel;
  }

  &-slate-grey {
    @extend .gray-colors;

    background: $slate-grey;
  }

  &-bluey-grey {
    @extend .gray-colors;

    background: $bluey-grey;
  }
}

.border-bg-colors {
  width: rem(50);
  height: rem(50);
  border-radius: 50%;

  &-border-color {
    @extend .border-bg-colors;

    background: lighten(#979797, 65%);
    border: rem(1) solid rgba($color: #000, $alpha: 0.1);
  }

  &-border-color-dark {
    @extend .border-bg-colors;

    background: darken(#979797, 10%);
    border: rem(1) solid rgba($color: #000, $alpha: 0.1);
  }

  &-border-color-light {
    @extend .border-bg-colors;

    background: lighten(#979797, 8%);
    border: rem(1) solid rgba($color: #000, $alpha: 0.1);
  }

  &-bg-color {
    @extend .border-bg-colors;

    background: #fff;
    border: rem(1) solid rgba($color: #000, $alpha: 0.1);
  }

  &-bg-color-dark {
    @extend .border-bg-colors;

    background: #0f1224;
    border: rem(1) solid rgba($color: #fff, $alpha: 0.5);
  }

  &-menu-bg-color {
    @extend .border-bg-colors;

    background: rgba(0, 0, 0, 0.75);
    border: rem(1) solid rgba($color: #fff, $alpha: 0.5);
  }
}

.control-colors {
  width: rem(50);
  height: rem(50);
  border-radius: 50%;

  &-success {
    @extend .control-colors;

    background: $success-color;
  }

  &-warning {
    @extend .control-colors;

    background: $warning-color;
  }

  &-error {
    @extend .control-colors;

    background: $error-color;
  }
}

.other-colors {
  width: rem(50);
  height: rem(50);
  border-radius: 50%;

  &-watermelon {
    @extend .control-colors;

    background: $watermelon;
  }
}
