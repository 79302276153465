.universe {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  color: white;
  background-image: linear-gradient(#000004, #1d255c);
}

.universe > canvas {
  position: sticky;
  top: rem(0);
  left: rem(0);
  z-index: 15;
}

.scroll-container {
  position: absolute;
  top: rem(0);
  left: rem(0);
  z-index: 20;
  width: rem(1);
  height: rem(1);
  visibility: hidden;
}

.scroll-container.scrollenabled {
  height: 200vh;
}

// Parent Topic Browser Loader
.parent-topic-loaded {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  visibility: visible;
  opacity: 1;
  @include transition(opacity 1s, visibility 1s);
}

.parent-topic-loaded.completed {
  visibility: hidden;
  opacity: 0;
}

// Scroll Instructions
.scroll-instructions {
  position: fixed;
  top: 48%;
  left: 50%;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  @include transition(opacity 1s, visibility 1s);
}

.scroll-instructions.visible {
  visibility: visible;
  opacity: 1;
}

.scroll-image {
  width: rem(31);
  height: rem(46);
  background-image: url('../../assets/images/icon-scroll.svg');
  background-repeat: no-repeat;
  filter: invert(1);
}

.scroll-instructions p {
  margin: 0;
}

// Shard Label

.shard-label {
  position: fixed;
  z-index: 50;
  margin: 0;
  padding: rem(10) rem(23) rem(13);
  overflow: hidden;
  font-weight: bold;
  font-size: rem(18);
  font-style: normal;
  font-stretch: normal;
  letter-spacing: rem(-0.25);
  background-color: black;
  border-radius: 40px;
  @include transition(opacity 1s, min-width 1s, height 1s);
}

.shard-label h3 {
  margin: 0;
}

.shard-label-hidden {
  min-width: rem(100);
  height: rem(44);
  cursor: auto;
  opacity: 0;
  pointer-events: none;
}

.shard-label-visible {
  min-width: rem(235);
  height: rem(60);
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
}

.shard-label-inactive {
  min-width: rem(100);
  height: rem(44);
  cursor: auto;
  opacity: 0.2;
  pointer-events: none;
}

.shard-label p {
  margin: 0;
}

.topic-status {
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: rem(12);
  @include transition(opacity 1s, width 1s);
}

.shard-label-hidden .topic-status,
.shard-label-inactive .topic-status {
  width: 0;
  opacity: 0;
}

.shard-label-visible .topic-status {
  width: rem(190);
  opacity: 1;
}

.topic-status span {
  color: #80838e;
}

.progress-bar {
  width: rem(68);
  height: rem(5);
  margin: 0 rem(6);
  overflow: hidden;
  background-color: #80838e;
  border-radius: 2px;
}

.progress {
  width: 0%;
  height: inherit;
  background-color: white;
}

// Top Nav
.top-nav-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 49;
  width: 100%;
  height: rem(240);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

ul.top-nav {
  position: fixed;
  top: rem(30);
  left: 50%;
  z-index: 50;
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transform: translate(-50%, 0);
  opacity: 0;
  @include transition(opacity 1s);
}

ul.top-nav.active {
  opacity: 1;
}

.top-nav li {
  display: flex;
  align-items: center;
  max-width: rem(31);
  height: rem(30);
  margin: 0 rem(25);
  padding: 0 rem(10) 0 rem(10.5);
  overflow: hidden;
  background: none;
  background-image: none;
  border: solid 1px;
  border-radius: 14.5px;
  cursor: pointer;
  @include transition(max-width 1s);
}

.top-nav li:first-child {
  padding-left: rem(13);
}

.top-nav li.topic-active {
  max-width: rem(300);
}

.top-nav li.topic-relationships {
  border-color: #977fff;
}

.top-nav li.topic-stigma {
  border-color: #ff4a69;
}

.top-nav li.topic-identity {
  border-color: #d5db41;
}

.top-nav li.topic-current-culture-of-dementia-care {
  border-color: #fb5927;
}

.top-nav li.topic-possibilities {
  border-color: #86e3ed;
}

.top-nav li .top-nav-number {
  margin-right: rem(7);
}

.top-nav li .top-nav-label {
  font-weight: bold;
  white-space: nowrap;
  opacity: 0;
  @include transition(opacity 1s);
}

.top-nav li.topic-active .top-nav-label {
  opacity: 1;
}

// Child topic browser
.parent-topic-container-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.load-relationships {
  background-image: url('../../assets/textures/bg-relationships-texture.jpg');
}

.load-stigma {
  background-image: url('../../assets/textures/bg-stigma-texture.jpg');
}

.load-identity {
  background-image: url('../../assets/textures/bg-identity-texture.jpg');
}

.load-current-culture-of-dementia-care {
  background-image: url('../../assets/textures/bg-culture-texture.jpg');
}

.load-possibilities {
  background-image: url('../../assets/textures/bg-possibilities-texture.jpg');
}

.sub-universe {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: white;
  background-image: linear-gradient(#000004, #1d255c);
}

// Child topic intro
.child-menu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.1;
  mix-blend-mode: multiply;

  @include transition(opacity 1s);

  pointer-events: none;
}

.child-menu-overlay.hide {
  opacity: 0;
}

.sub-menu-intro {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: rem(471);
  text-align: center;
  transform: translate(-50%, -50%);
  visibility: visible;
  opacity: 1;
  @include transition(opacity 0.6s, visibility 0.6s);

  p {
    margin-bottom: rem(50);
  }
  .child-intro-btn {
    width: rem(126);
  }

  @media (max-width: $size-md) {
    width: rem(300);
  }

  @media (max-width: $size-sm) {
    width: 90%;
  }
}

.sub-menu-intro > .child-intro-btn {
  background: none;
  background-image: none;
}

.sub-menu-intro.hidden {
  visibility: hidden;
  opacity: 0;
}

.sub-menu-intro p.sub-menu-progress-loader {
  opacity: 1;
  @include transition(opacity 1s);
}

.sub-menu-intro p.sub-menu-progress-loader.completed {
  opacity: 0;
}

.sub-menu-intro button.sub-menu-continue-btn {
  position: relative;
  width: 5.125rem;
  height: auto;
  padding: rem(7) rem(80) rem(7) rem(11);
  color: #fff;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 24px;
  opacity: 0;
  @include transition(opacity 1s);

  &::after {
    position: absolute;
    top: rem(5);
    left: rem(70);
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" fill="%23ffffff" opacity="0.25"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%23ffffff" /></svg>');
  }
}

.sub-menu-intro button.sub-menu-continue-btn:hover {
  border-color: rgba(255, 255, 255, 1);
}

.sub-menu-intro button.sub-menu-continue-btn.completed {
  opacity: 1;
}

// Child topic top nav
.sub-top-nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: rem(240);
  margin: 0;
  padding: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(238, 238, 238, 0));
  visibility: hidden;
  opacity: 0;
  @include transition(opacity 1s, visibility 1s);
}

.sub-top-nav.active {
  visibility: visible;
  opacity: 1;
}

a.sub-top-nav-back-btn {
  position: absolute;
  top: rem(20);
  left: rem(20);
  z-index: 51;
  width: rem(50);
  margin: 0 auto;
  color: white;
  -webkit-appearance: none;
}

.sub-top-nav-topic {
  position: absolute;
  top: rem(15);
  left: 50%;
  width: fit-content;
  min-height: rem(42);
  padding: rem(10) rem(20);
  color: white;
  white-space: pre-wrap;
  text-align: center;
  border: solid 1px;
  border-radius: 21px;
  transform: translate(-50%, 0);

  @media only screen and (max-width: $size-xs) {
    max-width: 45%;
  }
}

.sub-top-nav-topic.sub-top-nav-relationships {
  border-color: $periwinkle;
}

.sub-top-nav-topic.sub-top-nav-stigma {
  border-color: $watermelon;
}

.sub-top-nav-topic.sub-top-nav-identity {
  border-color: $sickley-yellow;
}

.sub-top-nav-topic.sub-top-nav-current-culture-of-dementia-care {
  border-color: $orangey-red;
}

.sub-top-nav-topic.sub-top-nav-possibilities {
  border-color: $tiffany-blue;
}

.sub-topic-status {
  position: absolute;
  top: rem(20);
  right: rem(20);
  z-index: 50;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: rem(12);
  @include transition(opacity 1s, width 1s);
}

.sub-topic-status span {
  color: white;
}

.sub-progress-bar {
  width: rem(46);
  height: rem(5);
  margin: 0 rem(6);
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.26);
  border-radius: 2px;

  @media only screen and (max-width: $size-xs) {
    max-width: rem(34);
  }
}

.sub-progress {
  width: 0%;
  height: inherit;
  background-color: white;
}

/* Child Topic Label */
.sub-topic-labels {
  display: inline;
}
.sub-topic-label {
  position: absolute;
  z-index: 50;
  margin: 0;
  padding: rem(13) rem(17) rem(15);
  overflow: hidden;
  font-weight: bold;
  font-size: rem(13);
  font-style: normal;
  font-stretch: normal;
  letter-spacing: rem(-0.25);
  background-color: black;
  border-radius: 40px;
  @include transition(opacity 0.7s, min-width 0.7s, height 0.7s);
}

.sub-topic-label-hidden {
  height: rem(42);
  visibility: hidden;
  cursor: auto;
  opacity: 0;
}

.sub-topic-label-visible {
  height: rem(42);
  visibility: visible;
  cursor: pointer;
  opacity: 0.8;
}

.sub-topic-label-visible:hover,
.sub-topic-label-visible:focus {
  height: rem(60);
  cursor: pointer;
  opacity: 1;
}

.sub-topic-label p {
  margin: 0;
}

.sub-topic-label-status {
  display: flex;
  align-items: center;
  margin-top: rem(-2);
  overflow: hidden;
  font-size: rem(12);
  @include transition(opacity 0.7s, width 0.7s);
}

.sub-topic-label-hidden .sub-topic-label-status,
.sub-topic-label-visible .sub-topic-label-status {
  width: 0;
  opacity: 0;
}

.sub-topic-label-visible:hover .sub-topic-label-status,
.sub-topic-label-visible:focus .sub-topic-label-status {
  width: rem(105);
  opacity: 1;
}

.sub-topic-label-status span {
  color: #80838e;
  font-weight: normal;
}

.sub-topic-label-status .progress {
  width: rem(7);
  height: rem(7);
  margin: rem(1) rem(6) 0;
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px #80838e;
  border-radius: 3.5px;
}

.sub-topic-label-status .progress.completed {
  background-color: #80838e;
}

.topic-description {
  width: rem(471);
  height: rem(117);
  margin: rem(27) 0 rem(52);
  color: $white;
  font-weight: normal;
  font-size: rem(34);
  font-family: $serif-font-family;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

/* Mobile Parent Topic Browser */
.mobile-parent-topic-browser {
  width: 100%;
  height: 100%;
  overflow: scroll;
  color: $white;
  background-image: linear-gradient(#000004, #1d255c);
}

.mobile-parent-topic-browser .bg-shards {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  visibility: visible;
  opacity: 1;
  filter: blur(rem(5));
  @include transition(opacity 1s, visibility 1s);

  pointer-events: none;
}

.mobile-parent-topic-browser .bg-shards.hidden {
  visibility: hidden;
  opacity: 0;
}

.mobile-parent-topic-browser .instructions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  text-align: center;
  transform: translate(-50%, -50%);
  visibility: visible;
  opacity: 1;
  @include transition(opacity 1s, visibility 1s);
}

.mobile-parent-topic-browser .instructions.hidden {
  visibility: hidden;
  opacity: 0;
}

.mobile-parent-topic-browser .instructions .scroll-image {
  background-image: url('../../assets/dark/swipe.svg');
  background-repeat: no-repeat;
  background-position: center;
  filter: invert(1);
}

.mobile-parent-topic-browser ul.top-nav {
  top: rem(20);
}

.mobile-parent-topic-browser .top-nav li {
  margin: 0 rem(8);
}

// Mobile shard carousel
$itemWidth: rem(350);
$itemMargin: rem(-50);

.mobile-parent-topic-browser .mobile-topic-carousel {
  position: absolute;
  top: 46%;
  z-index: 10;
  width: 100%;
  overflow: hidden;
  transform: translate(0, -50%);
  visibility: hidden;
  opacity: 0;
  @include transition(opacity 1s, visibility 1s);
}

.mobile-parent-topic-browser .mobile-topic-carousel.visible {
  visibility: visible;
  opacity: 1;
}

.mobile-parent-topic-browser .mobile-topic-carousel .mobile-topic-carousel-container {
  position: relative;
  left: 50%;
  z-index: 20;
  display: flex;
  @include transition(left 0.5s);
}

.mobile-parent-topic-browser .mobile-topic-carousel .mobile-topic-carousel-container.index-0 {
  left: 50%;
}

.mobile-parent-topic-browser .mobile-topic-carousel .mobile-topic-carousel-container.index-1 {
  left: calc(50% - (#{$itemWidth} + (#{$itemMargin} * 2)));

  @media only screen and (max-width: $size-sm) {
    left: calc(50% - (#{$itemWidth} + ((#{$itemMargin} * 2) * 2)));
  }
}

.mobile-parent-topic-browser .mobile-topic-carousel .mobile-topic-carousel-container.index-2 {
  left: calc(50% - (#{$itemWidth} + (#{$itemMargin} * 2)) * 2);

  @media only screen and (max-width: $size-sm) {
    left: calc(50% - (#{$itemWidth} + ((#{$itemMargin} * 2) * 2)) * 2);
  }
}

.mobile-parent-topic-browser .mobile-topic-carousel .mobile-topic-carousel-container.index-3 {
  left: calc(50% - (#{$itemWidth} + (#{$itemMargin} * 2)) * 3);

  @media only screen and (max-width: $size-sm) {
    left: calc(50% - (#{$itemWidth} + ((#{$itemMargin} * 2) * 2)) * 3);
  }
}

.mobile-parent-topic-browser .mobile-topic-carousel .mobile-topic-carousel-container.index-4 {
  left: calc(50% - (#{$itemWidth} + (#{$itemMargin} * 2)) * 4);

  @media only screen and (max-width: $size-sm) {
    left: calc(50% - (#{$itemWidth} + ((#{$itemMargin} * 2) * 2)) * 4);
  }
}

// Mobile shard item
.mobile-topic-carousel-item {
  display: flex;
  flex-direction: column;
  width: $itemWidth;
  margin: 0 $itemMargin;
  transform: translate(-50%, 0);
  @include transition(margin 0.5s);

  @media only screen and (max-width: $size-sm) {
    margin: 0 calc(#{$itemMargin} * 2);
  }
}

.mobile-topic-carousel-item.selected {
  margin: 0;
}

.mobile-topic-carousel-item-container {
  width: inherit;
  transform: scale(0.7);
  transition: transform 0.6s;
}

.mobile-topic-carousel-item.selected .mobile-topic-carousel-item-container {
  transform: scale(1);
}

.mobile-topic-carousel-item .mobile-shard-label.shard-label {
  visibility: hidden;
  opacity: 0;
  @include transition(opacity 1s, min-width 1s, height 1s);
}

.mobile-topic-carousel-item.selected .mobile-shard-label.shard-label {
  visibility: visible;
  opacity: 1;
}

.mobile-topic-carousel-item .img {
  display: block;
  width: inherit;
  height: rem(300);
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.mobile-topic-carousel-item .img.img-relationships {
  background-image: url('../../assets/images/img-mobile-relationships.png');
}

.mobile-topic-carousel-item .img.img-stigma {
  background-image: url('../../assets/images/img-mobile-stigma.png');
}

.mobile-topic-carousel-item .img.img-identity {
  background-image: url('../../assets/images/img-mobile-identity.png');
}

.mobile-topic-carousel-item .img.img-current-culture-of-dementia-care {
  background-image: url('../../assets/images/img-mobile-culture.png');
}

.mobile-topic-carousel-item .img.img-possibilities {
  background-image: url('../../assets/images/img-mobile-possibilities.png');
}

// Mobile shard label
.mobile-shard-label.shard-label {
  position: static;
  font-size: rem(16);
  text-align: center;
  background-color: transparent;
  opacity: 1;
}

.mobile-shard-label .topic-status {
  justify-content: center;
  margin: rem(2) 0 rem(10);
}

.mobile-shard-label.shard-label .topic-status .progress-bar {
  width: rem(50);
}

.mobile-shard-label.shard-label-inactive {
  min-width: rem(100);
  height: rem(44);
  cursor: auto;
  opacity: 0.2;
}

.mobile-shard-label .btn-explore {
  padding: rem(12) rem(34) rem(14) rem(33);
  color: white;
  font-weight: bold;
  font-size: rem(13);
  font-family: $base-font-family;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  background-color: black;
  border-radius: 24px;
}

/* Mobile Sub Topic Browser */
#root > .main-container .page.topics .mobile-child-topic-browser,
#root > .main-container .page.topics .mobile-child-topic-browser .mobile-child-topic-carousel {
  background: none;
}

.mobile-child-topic-browser {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: white;
}

.mobile-child-topic-browser.relationships {
  background-image: url('../../assets/textures/bg-relationships-texture.jpg');
  background-size: cover;
}

.mobile-child-topic-browser.stigma {
  background-image: url('../../assets/textures/bg-stigma-texture.jpg');
  background-size: cover;
}

.mobile-child-topic-browser.identity {
  background-image: url('../../assets/textures/bg-identity-texture.jpg');
  background-size: cover;
}

.mobile-child-topic-browser.current-culture-of-dementia {
  background-image: url('../../assets/textures/bg-culture-texture.jpg');
  background-size: cover;
}

.mobile-child-topic-browser.possibilities {
  background-image: url('../../assets/textures/bg-possibilities-texture.jpg');
  background-size: cover;
}

.mobile-child-topic-browser .bg-models {
  position: absolute;
  z-index: 15;
  width: 100%;
  height: 100%;
  margin: 0;
  background-image: url('../../assets/images/img-mobile-bg-models.png');
  background-position: center;
  background-size: cover;
  filter: blur(rem(5));
}

.mobile-child-topic-browser .fg-models {
  position: absolute;
  z-index: 16;
  width: 100%;
  height: 100%;
  margin: 0;
  background-position: center;
  background-size: cover;
  opacity: 0.6;
  filter: blur(rem(5));
}

.mobile-child-topic-browser.relationships .fg-models {
  background-image: url('../../assets/images/img-mobile-fg-models-relation.png');
}

.mobile-child-topic-browser.stigma .fg-models {
  background-image: url('../../assets/images/img-mobile-fg-models-stigma.png');
}

.mobile-child-topic-browser.identity .fg-models {
  background-image: url('../../assets/images/img-mobile-fg-models-identity.png');
}

.mobile-child-topic-browser.current-culture-of-dementia .fg-models {
  background-image: url('../../assets/images/img-mobile-fg-models-culture.png');
}

.mobile-child-topic-browser.possibilities .fg-models {
  background-image: url('../../assets/images/img-mobile-fg-models-poss.png');
}

.mobile-child-topic-browser .sub-menu-intro p.completed {
  display: none;
}

/* Mobile Sub Topic Top Nav */
.mobile-sub-top-nav .sub-top-nav-back-btn {
  top: rem(25);
  width: rem(20);
  height: rem(20);
  background-image: url('../../assets/dark/arrow-left.svg');
  background-repeat: no-repeat;
  background-size: rem(20) rem(20);
  filter: invert(1);
}

.mobile-sub-top-nav .sub-topic-status {
  top: rem(25);
}

.mobile-sub-top-nav {
  .sub-top-nav-topic {
    white-space: pre-wrap;
  }
}

/* Mobile Sub Topic Carousel */
$childItemHeight: rem(225);
$childItemMargin: rem(-40);

.mobile-child-topic-carousel {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 17;
  width: 100%;
  // width: rem(600);
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, 0);
  visibility: hidden;
  opacity: 0;
  @include transition(opacity 1s, visibility 1s);
}

.mobile-child-topic-carousel.visible {
  visibility: visible;
  opacity: 1;
}

.mobile-child-topic-carousel-container {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  will-change: transform;
  @include transition(top 1s ease-out);
}

@for $i from 0 through 16 {
  .mobile-child-topic-carousel-container.index-#{$i} {
    top: calc(50% - #{$childItemHeight} * #{$i});
  }
}

/* Mobile sub topic item */
.mobile-child-topic-carousel-item {
  position: relative;
  width: 100%;
  height: $childItemHeight;
  transform: translate(0, -50%);
}

.mobile-child-topic-carousel-item.selected {
  margin: 0;
}

.mobile-child-topic-carousel-item .img {
  display: block;
  width: inherit;
  height: rem(350);
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: scale(0.7);
  filter: blur(rem(1));
  @include transition(transform 1s);
}

.mobile-child-topic-carousel-item.selected .img {
  transform: scale(1);
}

@for $i from 1 through 8 {
  .mobile-child-topic-carousel.relationships .mobile-child-topic-carousel-item .img-#{$i} {
    background-image: url('../../assets/images/img-mobile-topic-model-relationships-0#{$i}.png');
  }

  .mobile-child-topic-carousel.stigma .mobile-child-topic-carousel-item .img-#{$i} {
    background-image: url('../../assets/images/img-mobile-topic-model-stigma-0#{$i}.png');
  }

  .mobile-child-topic-carousel.identity .mobile-child-topic-carousel-item .img-#{$i} {
    background-image: url('../../assets/images/img-mobile-topic-model-identity-0#{$i}.png');
  }

  .mobile-child-topic-carousel.possibilities .mobile-child-topic-carousel-item .img-#{$i} {
    background-image: url('../../assets/images/img-mobile-topic-model-possibilities-0#{$i}.png');
  }
}

.mobile-child-topic-carousel.current-culture-of-dementia-care {
  .mobile-child-topic-carousel-item {
    .img-1 {
      background-image: url('../../assets/images/img-mobile-topic-model-culture-01.png');
    }
  }
}

.mobile-child-topic-carousel.current-culture-of-dementia-care {
  .mobile-child-topic-carousel-item {
    .img-2 {
      background-image: url('../../assets/images/img-mobile-topic-model-culture-02.png');
    }
  }
}

.mobile-child-topic-carousel.current-culture-of-dementia-care {
  .mobile-child-topic-carousel-item {
    .img-3 {
      background-image: url('../../assets/images/img-mobile-topic-model-culture-03.png');
    }
  }
}

.mobile-child-topic-carousel.current-culture-of-dementia-care {
  .mobile-child-topic-carousel-item {
    .img-4 {
      background-image: url('../../assets/images/img-mobile-topic-model-culture-04.png');
    }
  }
}

.mobile-child-topic-carousel.current-culture-of-dementia-care {
  .mobile-child-topic-carousel-item {
    .img-5 {
      background-image: url('../../assets/images/img-mobile-topic-model-culture-05.png');
    }
  }
}

.mobile-child-topic-carousel.current-culture-of-dementia-care {
  .mobile-child-topic-carousel-item {
    .img-6 {
      background-image: url('../../assets/images/img-mobile-topic-model-culture-06.png');
    }
  }
}

.mobile-child-topic-carousel.current-culture-of-dementia-care {
  .mobile-child-topic-carousel-item {
    .img-7 {
      background-image: url('../../assets/images/img-mobile-topic-model-culture-07.png');
    }
  }
}

.mobile-child-topic-carousel.current-culture-of-dementia-care {
  .mobile-child-topic-carousel-item {
    .img-8 {
      background-image: url('../../assets/images/img-mobile-topic-model-culture-08.png');
    }
  }
}

/*  Mobile sub topic label */
.mobile-child-topic-label {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  height: rem(42);
  color: white;
  opacity: 0.2;
  @include transition(opacity 1s, height 1s, transform 1s);

  pointer-events: none;
}

.mobile-child-topic-label.left {
  transform: translate(-100%, -50%);
}

.mobile-child-topic-label.right {
  transform: translate(0%, -50%);
}

.mobile-child-topic-carousel-item.selected .mobile-child-topic-label {
  height: rem(76);
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
}

.mobile-child-topic-label {
  .topic-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 rem(10);
  }

  .img-arrow {
    width: rem(0);
    height: rem(48);
    margin: 0;
    background-image: url('../../assets/dark/arrow-circle-fill-right-mobile.svg');
    opacity: 0;
    @include transition(width 1s, opacity 1s, margin 1s);
  }

  .arrow-bg {
    width: 3rem;
    margin: 0 0 0 rem(22);
    background-color: #4a4b50;
    border-radius: 50%;
  }
}

.mobile-child-topic-carousel-item.selected .mobile-child-topic-label .img-arrow {
  width: rem(48);
  opacity: 1;
}
