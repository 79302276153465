.audio-title {
  color: black;
  font-weight: bold;
  font-size: rem(24);
  font-family: $base-font-family;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: rem(-0.25);
}

.audio-instructions {
  color: black;
  font-weight: bold;
  font-size: rem(12);
  font-family: $serif-font-family;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: rem(-0.25);
}
