/* prettier-ignore */
.about-icon {
  display: inline-block;
  width: rem(13);
  height: rem(13);
  margin-right: rem(7);
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 22 22"><path fill="grey" d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,11,20Zm-1-4h2V10H10Zm0-8h2V6H10Z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.active .about-icon {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 22 22"><path fill="white" d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,11,20Zm-1-4h2V10H10Zm0-8h2V6H10Z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.saved-icon {
  display: inline-block;
  width: rem(12);
  height: rem(12);
  margin-right: rem(8);
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 11.74 15.65"><path fill="grey" d="M9.74,2V12.09L6.89,10.36l-1-.63-1,.63L2,12.08V2H9.74m2-2H0V15.65l5.85-3.58,5.89,3.58V0Z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.active .saved-icon {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 11.74 15.65"><path fill="white" d="M9.74,2V12.09L6.89,10.36l-1-.63-1,.63L2,12.08V2H9.74m2-2H0V15.65l5.85-3.58,5.89,3.58V0Z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.share-icon {
  display: inline-block;
  width: rem(16);
  height: rem(12);
  margin-right: rem(4);
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 17"><path fill="grey" d="M13.5,12a2.48,2.48,0,0,0-1.9.89L4.88,9.23a2.28,2.28,0,0,0,0-1.46L11.6,4.11A2.49,2.49,0,1,0,11,2.5a2.48,2.48,0,0,0,.12.73L4.4,6.89a2.5,2.5,0,1,0,0,3.22l6.72,3.66A2.49,2.49,0,1,0,13.5,12Z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.user-icon {
  display: inline-block;
  width: rem(13);
  height: rem(15);
  margin-top: rem(3);
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 18.67 22"><path fill="black" d="M9.34,10.12A5.06,5.06,0,1,0,4.28,5.06,5.06,5.06,0,0,0,9.34,10.12Zm0,2.78a12.59,12.59,0,0,0-9.34,4,11.11,11.11,0,0,0,18.67,0A12.56,12.56,0,0,0,9.34,12.9Z"/></svg>') center center no-repeat;

  @include breakpoint(md) {
    margin-top: 0;
  }
}

/* prettier-ignore */
.profile-navigation .user-icon {
  width: rem(11);
  height: rem(15);
  margin-right: rem(8);
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 18.67 22"><path fill="grey" d="M9.34,10.12A5.06,5.06,0,1,0,4.28,5.06,5.06,5.06,0,0,0,9.34,10.12Zm0,2.78a12.59,12.59,0,0,0-9.34,4,11.11,11.11,0,0,0,18.67,0A12.56,12.56,0,0,0,9.34,12.9Z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.active .user-icon {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 18.67 22"><path fill="white" d="M9.34,10.12A5.06,5.06,0,1,0,4.28,5.06,5.06,5.06,0,0,0,9.34,10.12Zm0,2.78a12.59,12.59,0,0,0-9.34,4,11.11,11.11,0,0,0,18.67,0A12.56,12.56,0,0,0,9.34,12.9Z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.settings-icon {
  display: inline-block;
  width: rem(13);
  height: rem(14);
  margin-right: rem(7);
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16.258 15.986"><path fill="grey" d="M8.129,4.245a3.748,3.748,0,1,0,3.748,3.748A3.752,3.752,0,0,0,8.129,4.245Zm0,6.5a2.748,2.748,0,1,1,2.748-2.748A2.751,2.751,0,0,1,8.129,10.741ZM14.48,7.993c0-.1,0-.19-.008-.284l1.786-1.622-2.34-4.053L11.7,2.746a6.441,6.441,0,0,0-.642-.384L10.543,0H5.862L5.37,2.273a6.454,6.454,0,0,0-.658.369l-2.3-.736L.073,5.959,1.8,7.523c-.013.155-.02.312-.02.47,0,.095,0,.19.009.284L0,9.9l2.34,4.054,2.221-.712a6.518,6.518,0,0,0,.642.384l.512,2.361H10.4l.492-2.273a6.268,6.268,0,0,0,.658-.369l2.3.736,2.341-4.053L14.461,8.463C14.474,8.308,14.48,8.151,14.48,7.993Zm.435,2.233-1.534,2.656-2-.639-.2.141a5.315,5.315,0,0,1-.929.52l-.236.1-.429,1.981H6.521l-.444-2.05-.223-.1a5.439,5.439,0,0,1-.91-.545l-.206-.154-1.935.62L1.27,10.1l1.551-1.41L2.8,8.442c-.013-.149-.024-.3-.024-.449a5.412,5.412,0,0,1,.038-.614l.03-.255-1.5-1.363L2.877,3.1l2,.64.2-.142a5.282,5.282,0,0,1,.93-.519l.235-.1L6.669,1H9.736l.445,2.05.223.1a5.312,5.312,0,0,1,.909.545l.207.153,1.935-.621,1.533,2.657L13.437,7.3l.02.246c.013.149.023.3.023.449a5.19,5.19,0,0,1-.038.614l-.029.255Z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.active .settings-icon {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16.258 15.986"><path fill="white" d="M8.129,4.245a3.748,3.748,0,1,0,3.748,3.748A3.752,3.752,0,0,0,8.129,4.245Zm0,6.5a2.748,2.748,0,1,1,2.748-2.748A2.751,2.751,0,0,1,8.129,10.741ZM14.48,7.993c0-.1,0-.19-.008-.284l1.786-1.622-2.34-4.053L11.7,2.746a6.441,6.441,0,0,0-.642-.384L10.543,0H5.862L5.37,2.273a6.454,6.454,0,0,0-.658.369l-2.3-.736L.073,5.959,1.8,7.523c-.013.155-.02.312-.02.47,0,.095,0,.19.009.284L0,9.9l2.34,4.054,2.221-.712a6.518,6.518,0,0,0,.642.384l.512,2.361H10.4l.492-2.273a6.268,6.268,0,0,0,.658-.369l2.3.736,2.341-4.053L14.461,8.463C14.474,8.308,14.48,8.151,14.48,7.993Zm.435,2.233-1.534,2.656-2-.639-.2.141a5.315,5.315,0,0,1-.929.52l-.236.1-.429,1.981H6.521l-.444-2.05-.223-.1a5.439,5.439,0,0,1-.91-.545l-.206-.154-1.935.62L1.27,10.1l1.551-1.41L2.8,8.442c-.013-.149-.024-.3-.024-.449a5.412,5.412,0,0,1,.038-.614l.03-.255-1.5-1.363L2.877,3.1l2,.64.2-.142a5.282,5.282,0,0,1,.93-.519l.235-.1L6.669,1H9.736l.445,2.05.223.1a5.312,5.312,0,0,1,.909.545l.207.153,1.935-.621,1.533,2.657L13.437,7.3l.02.246c.013.149.023.3.023.449a5.19,5.19,0,0,1-.038.614l-.029.255Z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.resources-icon {
  display: inline-block;
  width: rem(14);
  height: rem(14);
  margin-right: rem(7);
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 11.6"><path fill="grey" d="M7,11.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.2-0.1-0.2-0.2-0.2c-0.1-0.1-0.4-0.1-0.7-0.1H0.7C0.3,10.7,0,10.4,0,10V0.7 C0,0.3,0.3,0,0.7,0h4.8c0.6,0,1.1,0.1,1.5,0.4c0,0,0,0,0.1,0c0,0,0,0,0.1,0C7.4,0.1,7.9,0,8.5,0h4.8C13.7,0,14,0.3,14,0.7V10 c0,0.4-0.3,0.7-0.7,0.7H8.5c-0.3,0-0.6,0-0.7,0.1c-0.1,0.1-0.1,0.1-0.2,0.2C7.6,11.3,7.3,11.6,7,11.6z M8.5,1.3 C8.2,1.3,8,1.4,7.8,1.5C7.7,1.6,7.7,1.7,7.7,1.9v7.6c0.3-0.1,0.6-0.1,0.8-0.1h4.2v-8H8.5z M1.3,9.4h4.2c0.2,0,0.5,0,0.9,0.1V1.9 c0-0.2-0.1-0.3-0.2-0.4C6,1.4,5.8,1.3,5.5,1.3H1.3L1.3,9.4z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.active .resources-icon {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 11.6"><path fill="white" d="M7,11.6c-0.3,0-0.6-0.2-0.6-0.6c0-0.2-0.1-0.2-0.2-0.2c-0.1-0.1-0.4-0.1-0.7-0.1H0.7C0.3,10.7,0,10.4,0,10V0.7 C0,0.3,0.3,0,0.7,0h4.8c0.6,0,1.1,0.1,1.5,0.4c0,0,0,0,0.1,0c0,0,0,0,0.1,0C7.4,0.1,7.9,0,8.5,0h4.8C13.7,0,14,0.3,14,0.7V10 c0,0.4-0.3,0.7-0.7,0.7H8.5c-0.3,0-0.6,0-0.7,0.1c-0.1,0.1-0.1,0.1-0.2,0.2C7.6,11.3,7.3,11.6,7,11.6z M8.5,1.3 C8.2,1.3,8,1.4,7.8,1.5C7.7,1.6,7.7,1.7,7.7,1.9v7.6c0.3-0.1,0.6-0.1,0.8-0.1h4.2v-8H8.5z M1.3,9.4h4.2c0.2,0,0.5,0,0.9,0.1V1.9 c0-0.2-0.1-0.3-0.2-0.4C6,1.4,5.8,1.3,5.5,1.3H1.3L1.3,9.4z"/></svg>') center center no-repeat;
}

/* prettier-ignore */
.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}
