.menu-wrapper {
  &.hide {
    display: none;
  }
}
.menu-drawer {
  &.hide {
    display: none;
  }

  @media only screen and (max-width: rem(1075)) {
    width: 50%;
    max-width: rem(840);
    border-radius: 50% 50% 0% 0% / 100% 100% 0% 0%;
  }

  .menu-btn {
    position: fixed;
    bottom: rem(-51);
    left: 50%;
    z-index: 10;
    width: 60%;
    max-width: rem(840);
    height: rem(100);
    padding-bottom: rem(50);
    overflow-y: hidden;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.75);
    border: none;
    border-radius: 50% 50% 0% 0% / 100% 100% 0% 0%;
    transform: translate(-50%, 0);
  }

  .menu-btn:hover {
    cursor: pointer;
  }
}

.ReactModalPortal {
  position: relative;
  z-index: 115;

  .ReactModal__Overlay {
    background-color: rgba(51, 51, 51, 0);
    transition: 1s;

    &.overlay-after-open-warning {
      background-color: rgba(51, 51, 51, 0.7);
      @include breakpoint(md) {
        overflow-y: unset;
      }
      .modal-body {
        p {
          width: 100%;
          margin-bottom: rem(10);
        }
      }
      .cta {
        width: 100%;
        background-color: transparent;
        a {
          -webkit-appearance: none;
        }
      }
      .cta-btn {
        width: 100%;
      }
    }
    &.overlay-after-open {
      background-color: rgba(51, 51, 51, 0.9);
    }
  }
}

.global-menu-modal {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(#000, #222);

  @include breakpoint(md) {
    height: fit-content;
    overflow: visible;
  }

  .glow {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    // height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), white 1250%);
    pointer-events: none;
  }
}

.no-style-btn {
  position: relative;
  z-index: 10;
  width: 2.5rem;
  margin-top: 0.5rem;
  padding: 1rem 0;
  color: white;
  text-align: center;
  background: transparent;
  border: none;

  .esc-btn {
    display: block;
    width: rem(21);
    height: rem(21);
    margin: auto;
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.51,10.51,0,0,0,10.5,0Zm0,20A9.5,9.5,0,1,1,20,10.5,9.51,9.51,0,0,1,10.5,20ZM14.65,5.65,10.5,9.79,6.35,5.65l-.7.7L9.79,10.5,5.65,14.65l.7.7,4.15-4.14,4.15,4.14.7-.7L11.21,10.5l4.14-4.15Z" fill="white" /></svg>')
      center center no-repeat;
  }
}

.close-btn:hover {
  cursor: pointer;
}

.slide-panel__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: -10%;
  width: 120%;
  background-color: rgba(0, 0, 0, 0);
}

.overlay-after-open {
  background: transparent;
}

.overlay-after-close {
  background-color: rgba(0, 0, 0, 0);
}

.slide-pane {
  display: flex;
  flex-direction: column;
  height: 97%;
  overflow: hidden;
  border-radius: 50% 50% 50% 50% / 25% 25% 0% 0%;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.5);
  transition: transform 0.75s;
  will-change: transform;

  @include breakpoint(md) {
    overflow: scroll;
  }
}

.slide-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(100%);
}

.slide-bottom.content-after-open,
.slide-bottom.content-after-open-warning {
  border: none;
  outline: none;
  transform: translateY(0%);
}

.slide-bottom.content-after-close {
  transform: translateY(100%);
}

.global-menu {
  position: relative;
  // z-index: 10;
  display: grid;
  grid-template-columns: 0.24fr 0.24fr 0.52fr;
  justify-content: center;
  padding: 8vh 16% 0 16%;
  padding-bottom: rem(40);
  column-gap: 1.375rem;
  box-shadow: none;

  @media only screen and (max-width: rem(1075)) {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    justify-content: center;
    width: 90%;
    height: 85%;
    padding: 2.5rem 1.25rem;
  }
  @include breakpoint(md) {
    height: fit-content;
  }

  @media only screen and (max-height: rem(1075)) {
    padding-top: 3vh;
  }
}

.parent-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: rem(218);
  height: fit-content;
  max-height: rem(544);
  overflow-y: scroll;

  @media only screen and (max-width: rem(1075)) {
    flex-direction: row;
    gap: 0.75rem;
    width: 90%;
    min-height: rem(76);
    max-height: 4.75rem;
    margin: auto;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @media only screen and (max-height: rem(750)) {
    height: fit-content;
    max-height: 70vh;
  }

  @media only screen and (max-height: $size-sm) {
    height: fit-content;
    max-height: 55vh;
  }

  @media only screen and (max-height: $size-xs) {
    height: fit-content;
    max-height: 45vh;
  }

  @media only screen and (max-height: $size-md) and (max-width: $size-md) {
    align-items: center;
    min-height: rem(76);
    margin-bottom: rem(10);
    overflow-y: hidden;
  }

  .parent-item-block {
    width: rem(218);
    margin-bottom: rem(16);
  }

  @media only screen and (min-width: rem(1076)) {
    div:last-child {
      margin-bottom: 0;
    }
  }

  .parent-item {
    display: inline-block;
    height: auto;
    overflow: hidden;
    color: #3a3c41;
    font-size: 1.125rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:focus {
      background-color: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.05);
      outline: none;
      box-shadow: rem(12) 0 rem(25) 0 rgba(0, 0, 0, 0.25);
    }

    p {
      margin-bottom: 0;
      color: $steel;
      font-weight: bold;
    }

    @media only screen and (max-width: rem(1075)) {
      width: 12.25rem;
      font-size: 1rem;
    }
  }

  .active {
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: rem(12) 0 rem(25) 0 rgba(0, 0, 0, 0.25);

    p {
      color: white;
    }
  }

  button {
    width: 100%;
    padding: 1.25rem 1.625rem 1.25rem 1.75rem;
    text-align: left;
    background-color: transparent;
    border: rem(1) solid rgba(255, 255, 255, 0.15);
    border-radius: 1.25rem;

    @media only screen and (max-width: rem(1075)) {
      width: 12.25rem;
      max-height: 4.7rem;
      padding: 0.938rem 0 1.313rem 1.25rem;
    }
  }

  button:hover {
    cursor: pointer;
  }

  .custom-meter,
  .custom-meter::-webkit-progress-bar {
    width: 8.313rem;
    height: rem(10);
    background-color: #3a3c41;
    border-radius: rem(5);

    @media only screen and (max-width: rem(1075)) {
      width: 8rem;
    }
  }

  .custom-meter::-moz-progress-bar {
    width: 8.313rem;
    height: rem(10);
    background-color: #3a3c41;
    border-radius: rem(5);
  }

  .custom-meter::-webkit-progress-value {
    border-radius: rem(5);
  }

  .custom-meter[data-color='periwinkle']::-webkit-progress-value {
    background-color: #977fff;
  }

  .custom-meter[data-color='watermelon']::-webkit-progress-value {
    background-color: #ff4a69;
  }

  .custom-meter[data-color='yellow']::-webkit-progress-value {
    background-color: #d5db41;
  }

  .custom-meter[data-color='orange']::-webkit-progress-value {
    background-color: #fb5927;
  }

  .custom-meter[data-color='blue']::-webkit-progress-value {
    background-color: #86e3ed;
  }

  .progress-nums {
    margin-left: 1rem;
    color: #9b9da6;
    font-size: 0.813rem;

    @media only screen and (max-width: rem(1075)) {
      margin-left: 1.25rem;
    }
  }
}

.parent-nav {
  scrollbar-width: none; // firefox support
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    height: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

.child-topics-viewer {
  display: flex;
  flex-direction: column;
  width: 13.625rem;
  height: fit-content;
  max-height: rem(544);
  overflow: scroll;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  scrollbar-width: none;
  @media only screen and (max-width: rem(1075)) {
    width: 90%;
    height: 40vh;
    margin: auto;
    overflow: scroll;
  }

  @media only screen and (max-height: rem(750)) {
    height: fit-content;
    max-height: 70vh;
  }

  @media only screen and (max-height: $size-sm) {
    height: fit-content;
    max-height: 55vh;
  }

  @media only screen and (max-height: $size-xs) {
    height: fit-content;
    max-height: 45vh;
  }

  .child-link {
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }

  .child-topics {
    display: grid;
    grid-template-columns: 0.85fr 0.15fr;
    height: 5.5rem;
    padding: rem(18) 0 rem(16) rem(20);
    color: #5f616b;
    font-size: rem(12);
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    transition: 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .child-title {
      color: white;
    }

    .not-explored {
      color: $steel;
    }

    .explore-radio {
      display: inline-block;
      width: rem(6);
      height: rem(6);
      margin-left: 0.25rem;
      border-radius: 50%;
    }

    .completed {
      background-color: #977fff;
    }

    .not-completed {
      background-color: none;
      border: 1px solid grey;
    }

    .child-link {
      display: flex;
      align-self: center;
      justify-content: flex-end;
      padding-right: rem(20);
      color: white;
    }
  }

  .child-link:focus .child-topics {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.child-topics-viewer::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  height: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.parent-preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28.75rem;
  height: 100%;
  max-height: rem(544);
  overflow: hidden;
  border-radius: 1rem;

  @media only screen and (max-width: rem(1075)) {
    order: -1;
    width: 100%;
    max-width: 90%;
    max-height: 7.625rem;
    margin: auto;
    object-fit: contain;
  }

  @media only screen and (max-height: $size-md) and (max-width: $size-md) {
    align-items: center;
    height: 32vh;
    margin-bottom: rem(10);
    overflow-y: hidden;
  }

  @media only screen and (max-height: rem(750)) {
    height: 70vh;
  }

  @media only screen and (max-height: $size-sm) {
    height: 55vh;
  }

  @media only screen and (max-height: $size-xs) {
    height: 45vh;
  }

  @media only screen and (max-height: rem(370)) {
    align-items: center;
    overflow-y: hidden;
  }

  .parent-background {
    position: absolute;
    top: 0;
    max-width: rem(460);
    height: auto;

    @media only screen and (max-height: rem(370)) {
      width: 100%;
      height: 200%;
      overflow: hidden;
    }

    @media only screen and (max-width: rem(1075)) {
      top: 0;
      width: 100%;
      max-width: 100%;
      height: 36.688rem;
    }
  }

  .parent-info {
    position: relative;
    top: 1rem;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    margin: 1rem;
    color: #717083;
    text-align: left;

    @media only screen and (max-width: $size-md) {
      top: 0.25rem;
    }

    @media only screen and (min-width: $size-xl) {
      top: 0.25rem;
      left: rem(20);
      left: 1.313rem;
      max-width: rem(300);
      margin: 0;
    }

    h3 {
      color: white;
      font-size: 1.5rem;
    }

    p {
      color: white;
      font-weight: normal;
      font-size: rem(20);
      font-family: $serif-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;

      @media only screen and (max-width: rem(1075)) {
        display: none;
      }

      @media only screen and (max-height: rem(370)) {
        display: none;
      }
    }

    a {
      color: white;
      text-decoration: none;
    }

    .explore-btn {
      width: 7.125rem;
      height: 2.625rem;
      margin-top: 0.75rem;
      padding: 0.75rem 1.313rem;
      color: black;
      font-weight: bold;
      font-size: 0.813rem;
      border-radius: 1.5rem;
      box-shadow: rem(5) rem(10) rem(25) 0 rgba(0, 0, 0, 0.4);

      .arrow {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.5rem;
        padding: rem(2) rem(6);
        color: white;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 1.5rem;

        @media only screen and (max-width: rem(1075)) {
          padding: 0.063rem 0.28rem;
          border-radius: 1rem;
        }
      }
    }

    .child-link:focus .explore-btn {
      outline: rem(1) solid white;
    }
  }
}

.hidden {
  display: none;
}

.topic-relationships {
  background-color: $periwinkle;
  .explore-btn {
    background-color: $periwinkle;
  }
  .explored {
    color: $periwinkle;
  }
}

.topic-stigma {
  background-color: $watermelon;

  .explore-btn {
    background-color: $watermelon;
  }
  .explored {
    color: $watermelon;
  }
}

.topic-identity {
  background-color: $sickley-yellow;

  .explore-btn {
    background-color: $sickley-yellow;
  }
  .explored {
    color: $sickley-yellow;
  }
}

.topic-current-culture-of-dementia-care {
  background-color: $orangey-red;

  .explore-btn {
    background-color: $orangey-red;
  }
  .explored {
    color: $orangey-red;
  }
}

.topic-possibilities {
  background-color: $tiffany-blue-dark;

  .explore-btn {
    background-color: #86e3ed;
  }
  .explored {
    color: $tiffany-blue-dark;
  }
}
