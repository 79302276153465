.completion-certificate {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  background-image: conic-gradient(
    from 0.25turn,
    rgba(156, 225, 234, 0.6),
    rgba(151, 127, 255, 0.6) 0.18turn,
    rgba(249, 114, 152, 0.6) 0.45turn,
    rgba(199, 137, 217, 0.6) 0.53turn,
    rgba(177, 127, 247, 0.6) 0.63turn,
    rgba(242, 122, 136, 0.6) 0.81turn,
    rgba(134, 227, 237, 0.6)
  );

  .certificate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: rem(596);

    .image-container {
      width: rem(200);

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  h1 {
    color: black;
    font-weight: bold;
    font-size: rem(24);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: rem(-0.25);
    text-align: center;
  }

  h2.name {
    color: black;
    font-weight: bold;
    font-size: rem(24);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: rem(-0.25);
    text-align: center;
  }

  .thank-you-message {
    color: black;
    font-weight: normal;
    font-size: rem(24);
    font-family: $serif-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  h2 {
    color: black;
    font-weight: bold;
    font-size: rem(13);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
  }

  .certificate-paragraph {
    max-width: rem(291);
    color: black;
    font-weight: normal;
    font-size: rem(13);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }

  .close-link {
    position: absolute;
    top: rem(21);
    left: rem(21);
  }

  .share-container {
    margin-bottom: rem(37);
    .share-link {
      margin: 0 rem(6);
    }

    .share-linkedin {
      display: inline-block;
      width: rem(24);
      height: rem(24);
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0ZM7.8,16.63H5.12V8.57H7.8ZM6.46,7.47h0a1.53,1.53,0,0,1-1.08-.41,1.34,1.34,0,0,1-.4-1,1.36,1.36,0,0,1,.41-1,1.56,1.56,0,0,1,1.1-.4,1.53,1.53,0,0,1,1.09.4,1.4,1.4,0,0,1,.41,1,1.35,1.35,0,0,1-.41,1A1.57,1.57,0,0,1,6.46,7.47Zm11,9.16H14.78V12.32c0-1.09-.38-1.83-1.35-1.83a1.48,1.48,0,0,0-1.38,1,1.92,1.92,0,0,0-.09.66v4.5H9.29s0-7.3,0-8.06H12V9.71a2.68,2.68,0,0,1,2.42-1.33c1.76,0,3.08,1.15,3.08,3.63Z"/></svg>')
        center center no-repeat;
    }

    .share-twitter {
      display: inline-block;
      width: rem(24);
      height: rem(24);
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm5.022,8.577c.005.108.008.217.008.326a7.136,7.136,0,0,1-7.184,7.184h0a7.139,7.139,0,0,1-3.87-1.135,5,5,0,0,0,.6.035,5.067,5.067,0,0,0,3.136-1.08,2.53,2.53,0,0,1-2.359-1.754,2.567,2.567,0,0,0,.475.046A2.524,2.524,0,0,0,7.5,12.11,2.526,2.526,0,0,1,5.47,9.635V9.6a2.517,2.517,0,0,0,1.144.315,2.526,2.526,0,0,1-.782-3.37,7.173,7.173,0,0,0,5.205,2.638,2.487,2.487,0,0,1-.066-.576,2.526,2.526,0,0,1,4.368-1.727,5.059,5.059,0,0,0,1.6-.613,2.536,2.536,0,0,1-1.11,1.4,5.04,5.04,0,0,0,1.45-.4A5.154,5.154,0,0,1,16.022,8.577Z"/></svg>')
        center center no-repeat;
    }

    .share-facebook {
      display: inline-block;
      width: rem(24);
      height: rem(24);
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg"  width="22" height="22" viewBox="0 0 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve"><path d="M11,0C4.9,0,0,4.9,0,11s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0z M14.4,6.1h-1.7c-0.3,0-0.6,0.2-0.7,0.6c0,0,0,0.1,0,0.1v1.5 h2.3L14.1,11h-2v7.4H9.1V11H7.6V8.4H9V6.7c0-1.2,0.6-3.1,3.1-3.1h2.3V6.1z"/></svg>')
        center center no-repeat;
    }
  }
}
