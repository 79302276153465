.resources-modules {
  max-width: rem(460);

  // &.loading {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   height: 100%;
  //   width: 100%
  // }

  .resources-modules-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      color: white;
      font-weight: bold;
      font-size: rem(24);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: rem(-0.25);
    }
  }

  .resources-parent {
    color: white;
    font-weight: normal;
    font-size: rem(13);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .resources-module-container {
    margin-bottom: rem(27);

    .no-modules {
      color: $steel;
      font-weight: normal;
      font-size: rem(13);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .resource {
      position: relative;
      z-index: 50;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: rem(460);
      margin: rem(5) 0 rem(7) 0;
      padding: rem(6) 0;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 6px;
      box-shadow: rem(12) 0 rem(25) 0 rgba(0, 0, 0, 0.25);
      transition: 0.3s;

      @media (max-width: $size-sm) {
        width: 100%;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
        box-shadow: rem(12) 0 rem(25) 0 rgba(0, 0, 0, 0.25);
        cursor: pointer;
      }

      .module-link-image {
        min-width: rem(20);
        height: rem(20);
        background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.334 5H5.001c-.92 0-1.667.746-1.667 1.667V15c0 .92.746 1.667 1.667 1.667h8.333c.92 0 1.667-.747 1.667-1.667v-3.333m-3.334-8.334h5m0 0v5m0-5-8.333 8.334' stroke='%23ffffff' stroke-width='1.667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        transition: 0.3s;
      }
    }

    .module-info-container {
      display: flex;
      flex-direction: row;
      margin-left: rem(16);
    }

    .module-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        display: inline;
        margin: rem(3) rem(4) rem(2) 0;
        color: white;
        font-weight: normal;
        font-size: rem(12);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: rem(0.25);
      }
    }
  }
}
