.alert {
  position: fixed;
  top: 12%;
  left: 50%;
  z-index: 100;
  width: auto;
  height: auto;
  padding: rem(10) rem(20);
  color: black;
  font-family: $base-font-family;
  text-align: center;
  background-color: white;
  border-radius: rem(5);
  transform: translate(-50%, 0);

  @media (min-width: $size-sm) {
    top: 10%;
  }
}

.alert-enter {
  opacity: 0;
}

.alert-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
