$particleNum: 200;
$particleBaseSize: 10;

.particle-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 25vh;
  overflow: hidden;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  -webkit-mask-image: linear-gradient(to top, black 80%, transparent);
  -moz-mask-image: linear-gradient(to top, black 80%, transparent);
  mask-image: linear-gradient(to top, black 80%, transparent);
  &::after {
    position: absolute;
    top: 0;
    width: 100%;
    height: rem(200);
    content: '';
  }
}

.particle-wrapper {
  position: absolute;
  left: rem(-10);
  transform: translateY(1vh);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  .particle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
  }
}

.particle-container {
  // TODO: remove motion or static based on design review
  &.static {
    .particle-wrapper {
      @for $i from 1 through $particleNum {
        &:nth-child(#{$i}) {
          $circleSize: random($particleBaseSize);

          position: absolute;
          bottom: random(500) + px;
          left: random(2100) + px;
          width: $circleSize + px;
          height: $circleSize + px;
          @include breakpoint(md) {
            left: random(1200) + px;
          }
        }
      }
    }
  }

  // TODO: remove motion or static based on design review
  &.motion {
    .particle-wrapper {
      @for $i from 1 through $particleNum {
        &:nth-child(#{$i}) {
          $circleSize: random($particleBaseSize);

          width: $circleSize + px;
          height: $circleSize + px;

          $startPositionY: random(10) + 25;
          $framesName: 'move-frames-' + $i;
          $moveDuration: 35000 + random(22000) + ms;

          animation-name: #{$framesName};
          animation-duration: $moveDuration;
          animation-delay: random(37000) + ms;

          @keyframes #{$framesName} {
            from {
              transform: translate3d(#{random(100) + vw}, #{$startPositionY + vh}, 0);
            }

            to {
              transform: translate3d(
                #{random(100) + vw},
                #{- $startPositionY - random(30) + vh},
                0
              );
            }
          }
          .particle {
            animation-delay: random(1000) + ms;
          }
        }
      }
    }
  }
}

.stigma {
  .particle {
    background-image: radial-gradient(
      hsl(350, 100%, 65%),
      hsl(350, 100%, 65%) 10%,
      hsla(180, 100%, 80%, 0) 56%
    );
  }
}
.relationships {
  .particle {
    background-image: radial-gradient(
      hsl(251, 100%, 75%),
      hsl(251, 100%, 75%) 10%,
      hsla(180, 100%, 80%, 0) 56%
    );
  }
}
.current-culture-of-dementia-care {
  .particle {
    background-image: radial-gradient(
      hsl(14, 96%, 57%),
      hsl(14, 96%, 57%) 10%,
      hsla(180, 100%, 80%, 0) 56%
    );
  }
}
.identity {
  .particle {
    background-image: radial-gradient(
      hsl(62, 68%, 56%),
      hsl(62, 68%, 56%) 10%,
      hsla(180, 100%, 80%, 0) 56%
    );
  }
}
.possibilities {
  .particle {
    background-image: radial-gradient(
      hsl(186, 74%, 73%),
      hsl(186, 74%, 73%) 10%,
      hsla(180, 100%, 80%, 0) 56%
    );
  }
}

.page.about,
.page.resources,
.page.saved,
.page.account,
.page.privacy,
.page.terms {
  & > .particle-container {
    z-index: 1;
    .particle-wrapper:nth-of-type(1n) {
      .particle {
        background-image: radial-gradient(
          hsl(251, 100%, 75%),
          hsl(251, 100%, 75%) 10%,
          hsla(180, 100%, 80%, 0) 56%
        );
      }
    }
    .particle-wrapper:nth-of-type(3n) {
      .particle {
        background-image: radial-gradient(
          hsl(14, 96%, 57%),
          hsl(14, 96%, 57%) 10%,
          hsla(180, 100%, 80%, 0) 56%
        );
      }
    }
    .particle-wrapper:nth-of-type(5n) {
      .particle {
        background-image: radial-gradient(
          hsl(186, 74%, 73%),
          hsl(186, 74%, 73%) 10%,
          hsla(180, 100%, 80%, 0) 56%
        );
      }
    }
  }
}

.main-container {
  .particle-container {
    z-index: 1;
    .particle-wrapper:nth-of-type(1n) {
      .particle {
        background-image: radial-gradient(
          hsl(251, 100%, 75%),
          hsl(251, 100%, 75%) 10%,
          hsla(180, 100%, 80%, 0) 56%
        );
      }
    }
    .particle-wrapper:nth-of-type(3n) {
      .particle {
        background-image: radial-gradient(
          hsl(14, 96%, 57%),
          hsl(14, 96%, 57%) 10%,
          hsla(180, 100%, 80%, 0) 56%
        );
      }
    }
    .particle-wrapper:nth-of-type(5n) {
      .particle {
        background-image: radial-gradient(
          hsl(186, 74%, 73%),
          hsl(186, 74%, 73%) 10%,
          hsla(180, 100%, 80%, 0) 56%
        );
      }
    }
  }
  .relationships {
    .particle-container {
      .particle-wrapper {
        .particle {
          background-image: radial-gradient(
            hsl(251, 100%, 75%),
            hsl(251, 100%, 75%) 10%,
            hsla(180, 100%, 80%, 0) 56%
          );
        }
      }
    }
  }
  .stigma {
    .particle-container {
      .particle-wrapper {
        .particle {
          background-image: radial-gradient(
            hsl(350, 100%, 65%),
            hsl(350, 100%, 65%) 10%,
            hsla(180, 100%, 80%, 0) 56%
          );
        }
      }
    }
  }
  .identity {
    .particle-container {
      .particle-wrapper {
        .particle {
          background-image: radial-gradient(
            hsl(62, 68%, 56%),
            hsl(62, 68%, 56%) 10%,
            hsla(180, 100%, 80%, 0) 56%
          );
        }
      }
    }
  }
  .current-culture-of-dementia-care {
    .particle-container {
      .particle-wrapper {
        .particle {
          background-image: radial-gradient(
            hsl(14, 96%, 57%),
            hsl(14, 96%, 57%) 10%,
            hsla(180, 100%, 80%, 0) 56%
          );
        }
      }
    }
  }
  .possibilities {
    .particle-container {
      .particle-wrapper {
        .particle {
          background-image: radial-gradient(
            hsl(186, 74%, 73%),
            hsl(186, 74%, 73%) 10%,
            hsla(180, 100%, 80%, 0) 56%
          );
        }
      }
    }
  }
}
