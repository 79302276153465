.next-steps {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  scroll-snap-align: start;

  .next-steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      max-width: rem(244);
      margin-bottom: rem(80);
      color: white;
      font-weight: normal;
      font-size: rem(20);
      font-family: $serif-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;

      @media only screen and (min-width: $size-md) {
        max-width: rem(459);
      }
    }

    .next-steps-actions-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (min-width: $size-lg) {
        flex-direction: row;
      }

      .action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;

        @media only screen and (min-width: $size-lg) {
          width: 30%;
        }

        h2 {
          color: white;
          font-weight: 600;
          font-size: rem(31);
          font-family: $base-font-family;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.15;
          letter-spacing: rem(-1);
          text-align: center;

          @media only screen and (min-width: $size-lg) {
            font-size: rem(40);
          }
        }

        a,
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: rem(232);
          height: rem(54);
          color: #53545c;
          font-weight: 600;
          font-size: rem(18);
          font-family: $base-font-family;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: rem(-0.25);
          text-align: center;
          background-color: white;
          border-radius: rem(40);
          box-shadow: rem(5) rem(10) rem(25) 0 rgba(0, 0, 0, 0.25);
          transition: 0.3s;

          &:hover {
            color: #53545c;
            text-decoration: none;
            background-color: #dadadc;
          }
        }
      }

      .next-topic {
        @include breakpoint(sm) {
          padding-bottom: rem(100);
        }

        a {
          color: white;
          text-decoration: none;
          background: black;
          &:hover {
            color: white;
            text-decoration: none;
            background-color: #232324;
          }
        }
      }

      .divider {
        width: 100%;
        max-width: rem(300);
        height: rem(1);
        margin: rem(30);
        border: solid rem(1) white;
        opacity: 0.5;

        @media only screen and (min-width: $size-lg) {
          width: rem(1);
          height: rem(163);
          margin: 0 rem(134);
        }
      }
    }
  }
}
