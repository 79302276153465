.cracked-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  img {
    width: 100%;
    max-width: rem(135);

    @media (max-width: $size-md) {
      margin-top: rem(10);
    }
  }
}

.auth-navigation {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: rem(40);
  background: black;
  -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent);
  -moz-mask-image: linear-gradient(to bottom, black 90%, transparent);
  mask-image: linear-gradient(to bottom, black 90%, transparent);

  @media (max-width: $size-md) {
    padding: rem(15);
  }
  .auth-links {
    display: flex;
    margin: 0;
    color: #9b9da6;
  }
  li {
    margin: 0 rem(10) 0 0;
    padding: 0;
    list-style: none;
  }
  a {
    color: $secondary-color;
    text-decoration: underline;
  }
}

.auth-navigation.hide-nav {
  display: none;
}
