form {
  label {
    display: block;
    width: 100%;
    margin-bottom: 0.3rem;
    font-weight: 600;
    font-size: rem(13);
    text-align: left;
  }

  select,
  input[type='text'],
  input[type='password'],
  input[type='email'],
  textarea {
    width: 100%;
    height: rem(54);
    margin-bottom: 0.7rem;
    padding: 0.8rem 1rem;
    color: $secondary-color;
    background-color: transparent;
    border: 1px solid $border-color-dark;
    border-radius: 6px;
    resize: none;

    @media (min-width: $size-md) {
      padding: 1.1rem 1rem;
    }
  }
  input[type='submit'].btn {
    width: 100%;
    margin-top: 1.1rem;
    margin-bottom: 1rem;
  }

  input[type='radio'] {
    display: inline-block;
    width: rem(20);
    height: rem(20);
    border-color: $border-color-dark;

    @media (min-width: $size-md) {
      width: rem(26);
      height: rem(26);
    }
  }

  .form-group {
    .error-state {
      border: 1px solid $watermelon;
    }
    .error-message {
      color: $watermelon;
      text-align: left;
    }
  }

  .input-inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    input,
    label {
      display: inline-block;
      width: auto;
    }
  }

  .forgot-password-link {
    width: auto;
    color: $secondary-color;
    text-decoration: underline;
  }

  // Hidden labels for a11y purposes
  .hidden-label {
    height: 0;
    opacity: 0;
  }

  // overwrite browser form filling input highlight
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 rem(50) $dark-purple inset;
    -webkit-text-fill-color: $secondary-color;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;

    + label {
      position: relative;
      padding-left: rem(24);
      font-weight: 400;
      font-size: rem(13);
      line-height: rem(15);
      cursor: pointer;
    }

    + label::before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: rem(15);
      height: rem(15);
      border: 1px solid $border-color-dark;
      border-radius: 3px;
      content: '';
    }

    &:checked + label::before {
      background-image: url(../../components/auth/login/assets/checkmark.svg);
      background-repeat: no-repeat;
      background-position-x: 60%;
      background-position-y: center;
      background-size: 70%;
      border: 1px solid white;
    }
  }

  ul.radio-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: block;
      width: 100%;

      label.radio {
        position: relative;
        top: rem(-3);
        left: rem(10);
        display: inline-block;
        height: rem(30);
        font-size: rem(20);
        line-height: normal;
        letter-spacing: rem(-0.25);
        cursor: pointer;

        @media (min-width: $size-md) {
          top: rem(-5);
          font-size: rem(26);
          line-height: rem(32);
        }
      }
    }
  }

  .password-group {
    position: relative;

    .password-visibility {
      position: absolute;
      top: 0;
      right: 0;
      width: 5rem;
      margin-top: 1.8rem;
      padding: 0.8rem 0;
      color: $dark-gray;
      text-align: center;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &::before {
        position: absolute;
        top: 0.4rem;
        height: rem(32);
        border-left: 1px solid $dark-gray;
        content: '';
      }

      @media (min-width: $size-md) {
        margin-top: 1.5rem;
        padding: rem(18) 0;

        &::before {
          top: 0.7rem;
          left: 0;
        }
      }
    }

    .menu {
      position: absolute;
      top: rem(-31);
      right: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      padding-top: rem(20);
      overflow: scroll;
      text-align: left;
      background: $bg-color-dark;
      border-right: 1px solid $border-color-dark;
      border-bottom: 1px solid $border-color-dark;
      border-left: 1px solid $border-color-dark;
      border-radius: 0 0 6px 6px;
      box-shadow: none;

      ul {
        display: block;
      }

      li {
        width: 100%;
        margin-top: 0;
        margin-bottom: rem(4);
        padding: rem(10);

        button {
          width: 100%;
          color: $bluey-grey;
          text-align: left;

          &:focus {
            color: white;
          }
        }
      }
    }

    input[type='password'] {
      + .visibility > .password-hide {
        display: none;
        color: rgba(255, 255, 255, 0.35);
      }

      + .visibility > .password-show {
        display: inline-block;
        margin: 0 rem(21);
        color: rgba(255, 255, 255, 0.35);
      }
    }

    input[type='text'] {
      + .visibility > .password-hide {
        display: inline-block;
        margin: 0 rem(24);
        color: rgba(255, 255, 255, 0.35);
      }

      + .visibility > .password-show {
        display: none;
        color: rgba(255, 255, 255, 0.35);
      }
    }
  }

  .user-type-dropdown {
    position: relative;
    width: 100%;

    input[type='text'] {
      position: relative;
      z-index: 200;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .user-type-input {
      margin-bottom: 0;
      background: #0f1224;

      &::placeholder {
        color: rgba(255, 255, 255, 0.35);
      }
    }
  }

  .dropdown-button-container {
    position: relative;
    right: 0;
    width: 100%;

    .dropdown-toggle {
      position: absolute;
      top: rem(-67);
      right: 100;
      z-index: 250;
      width: 100%;
      padding: rem(10) 0;
      border: 1px solid transparent;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:focus {
        padding-top: rem(10);
        padding-bottom: rem(10);
        border: 1px solid white;
        border-radius: rem(6);
        img {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-arrow {
      float: right;
      width: rem(73);
      height: rem(32);
      border-left: 1px solid $dark-gray;

      @media (min-width: $size-sm) {
        width: rem(77);

        &:focus {
          width: rem(76);
        }
      }

      img {
        vertical-align: bottom;
      }
    }
  }

  .theme-light {
    select,
    input[type='text'],
    input[type='password'],
    input[type='email'],
    textarea,
    input[type='ratio'] {
      border-color: $border-color;
    }
  }
}

.register-btn {
  position: relative;
  z-index: 14;
  width: 100%;
  margin-bottom: rem(15);
}

.login-btn {
  width: 100%;
  margin-top: rem(30);
  margin-bottom: rem(15);
}
