.default-modal {
  max-width: 50vw;
  height: auto;
  background-color: white;
  border-radius: rem(6);

  @media screen and (max-width: $size-lg) {
    max-width: 80vw;
  }

  @media screen and (max-width: $size-sm) {
    max-width: 90vw;
    height: rem(118);
  }

  .first-cta {
    margin-top: rem(10);
    text-decoration: underline;
  }

  .first-cta:hover {
    cursor: pointer;
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: rem(10);
    margin-left: rem(22);
    padding: rem(20) 0;
    color: black;
    font-size: rem(13);

    @media screen and (max-width: $size-sm) {
      flex-direction: column;
      align-items: flex-end;
      margin-right: rem(16);
      margin-left: rem(16);
    }

    .cta {
      display: flex;
      align-items: center;
      width: 30%;

      @media screen and (max-width: $size-sm) {
        margin-top: rem(10);
      }
    }

    .cta-btn {
      width: rem(142);
      height: rem(42);
      margin: 0;
      // margin-left: rem(12);
      padding: rem(11) rem(20) rem(15);
    }
  }

  .confirm-delete {
    width: 30%;
    text-align: center;
  }

  .description {
    width: 40%;
    margin-bottom: 0;
  }
}
