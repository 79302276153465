.standard-content-top-nav {
  .close-link {
    position: absolute;
    top: rem(50);
    left: rem(50);

    @include breakpoint(md) {
      left: rem(25);
    }
  }
}

.profile-navigation {
  position: absolute;
  top: 42%;
  left: rem(50);
  z-index: 51;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ul {
    max-width: rem(180);
    margin-left: 0;
    padding-bottom: rem(20);
    font-size: 1.125rem;
    list-style: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  }

  a {
    color: #9b9da6;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    color: #9b9da6;
  }

  .active {
    color: white;
    text-decoration: none;
  }

  @include breakpoint(md) {
    @media only screen and (max-height: rem(450)) {
      display: none;
    }
  }

  @include breakpoint(sm) {
    display: none;
  }
}

.standard-content-container {
  position: relative;
  z-index: 50;
  height: 100%;
  margin: 0 auto;
  padding: 0 0 rem(25) 0;
  overflow-y: scroll;
  color: #9b9da6;

  @include breakpoint(md) {
    padding: 0 0 rem(25) 0;
  }

  @include breakpoint(sm) {
    padding: 0 0 rem(25) 0;
  }

  .content-wrapper {
    width: 70%;
    height: fit-content;
    margin: 10vh auto 0;
    padding: rem(50) 0 rem(50) rem(50);

    @include breakpoint(xl) {
      max-width: 60%;
    }

    @include breakpoint(lg) {
      max-width: 50%;
    }

    @include breakpoint(md) {
      max-width: 52%;
    }

    @include breakpoint(sm) {
      max-width: 90%;
    }

    @include breakpoint(2x) {
      max-width: rem(830);
    }
    h2,
    h3,
    h4 {
      color: white;
    }

    h2 {
      margin-bottom: rem(60);

      @include breakpoint(lg) {
        max-width: rem(800);
        margin-bottom: rem(40);
      }
    }
    .content {
      h2 {
        margin: rem(28) 0;
      }

      h3 {
        font-weight: 600;
        font-size: rem(20);
      }

      li,
      p {
        font-weight: 400;
        font-size: rem(20);
        font-family: $serif-font-family;
      }
      a {
        color: white;
        font-family: $serif-font-family;
      }
    }
  }
}

.account-privacy {
  @include breakpoint(md) {
    @media only screen and (max-height: rem(450)) {
      padding: 0 0 rem(100) 0;
    }
  }
}
