//  *** Buttons ***
// These are button elements
.btn-text {
  display: inline;
  margin: 0;
  padding: 0;
  color: $white;
  text-decoration: underline;
}

.btn {
  display: block;
  margin-bottom: rem(24);
  font-weight: 600;
  border-radius: rem(25);

  &.btn-lg {
    height: auto;
    padding: rem(13) rem(43);
    font-size: rem(18);
    line-height: rem(24);
  }

  &.btn-md {
    height: auto;
    padding: rem(15) rem(26);
    font-size: rem(13);
    line-height: rem(16);
  }

  &.btn-sm {
    height: auto;
    padding: rem(9) rem(11);
    font-size: rem(12);
    line-height: rem(12);
  }

  // black button
  &.btn-primary {
    color: $white;
    background-color: $black;
    border: 1px solid $black;
    box-shadow: rem(2) rem(2) rem(7) 0 rgba(0, 0, 0, 1);
    &:hover {
      background-color: $black-hover;
      border: 1px solid $black-hover;
    }
  }

  &.btn-secondary {
    color: $white;
    background-color: transparent;
    border: 1px solid $border-color-dark;
    &:hover {
      color: $white;
      border: 1px solid $border-color-dark-hover;
    }
    &.no-border {
      border: none;
    }

    &.next {
      width: 100%;
      max-width: rem(100);
    }
    &.continue {
      width: 100%;
      min-width: rem(125);
      max-width: rem(134);
    }
  }

  &.btn-ternary {
    color: $slate-grey;
    background-color: $white;
    border: 1px solid $white;
    box-shadow: rem(2) rem(2) rem(7) 0 rgba(0, 0, 0, 1);

    &:hover {
      background-color: $white-hover;
      border: 1px solid $white-hover;
    }
  }

  &.btn-signup {
    width: 75%;
    margin: 0 auto;
    opacity: 0.35;
    transition: 0.3s;
    &:hover {
      color: white;
      text-decoration: none;
      border: 1px solid $white;
      cursor: pointer;
      opacity: 1;
    }
  }

  &.continue-btn {
    width: 75%;
    margin: 0 auto;
    color: $slate-grey;
    background-color: white;
    &:hover {
      color: $slate-grey;
      text-decoration: none;
      background-color: $white-hover;
      cursor: pointer;
    }
  }

  .guest-signup,
  .account-signup {
    color: white;

    // font-family: $base-font-family;
    // line-height: 1.33;
    // letter-spacing: rem(-0.25);
  }

  &.btn-icon {
    height: auto;
    color: $secondary-color;
    font-weight: 400;
    line-height: rem(22);
    background-color: transparent;
    border: 1px solid $border-color;

    .icon-facebook {
      width: rem(22);
      height: rem(22);
      margin-right: rem(11);
      vertical-align: bottom;
      content: url('../../assets/images/icon-facebook.svg');
    }

    .icon-google {
      width: rem(22);
      height: rem(22);
      margin-right: rem(11);
      vertical-align: bottom;
      content: url('../../assets/images/icon-google.svg');
    }
  }

  &.btn-icon-lg {
    position: relative;
    height: auto;
    padding: rem(17) rem(30) rem(17) rem(65);
    font-weight: normal;
    font-size: rem(13);
    line-height: rem(16);

    &.btn-primary.google {
      &::before {
        position: absolute;
        top: rem(12);
        left: rem(26);
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26.52"><path d="M17.77,20h0l4.27,3.31A12.94,12.94,0,0,0,26,13.57a15.3,15.3,0,0,0-.23-2.7H13.26V16h7.17A6.16,6.16,0,0,1,17.77,20Z" fill="%234285f4"/><path d="M13.26,5.25a7.2,7.2,0,0,1,5.09,2l3.79-3.79A12.76,12.76,0,0,0,13.26,0,13.27,13.27,0,0,0,1.41,7.31l4.41,3.42A7.91,7.91,0,0,1,13.26,5.25Z" fill="%23ea4335"/><path d="M1.41,7.31a13.28,13.28,0,0,0,0,11.91L5.82,15.8a7.86,7.86,0,0,1,0-5.07Z" fill="%23fbbc04"/><path d="M17.78,20a8,8,0,0,1-12-4.21h0L1.41,19.22a13.26,13.26,0,0,0,11.85,7.3,12.66,12.66,0,0,0,8.79-3.2Z" fill="%2334a853"/></svg>');
      }
    }

    &.btn-secondary.google {
      &::before {
        position: absolute;
        top: rem(12);
        left: rem(26);
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26.52"><path d="M17.77,20h0l4.27,3.31A12.94,12.94,0,0,0,26,13.57a15.3,15.3,0,0,0-.23-2.7H13.26V16h7.17A6.16,6.16,0,0,1,17.77,20Z" fill="%234285f4"/><path d="M13.26,5.25a7.2,7.2,0,0,1,5.09,2l3.79-3.79A12.76,12.76,0,0,0,13.26,0,13.27,13.27,0,0,0,1.41,7.31l4.41,3.42A7.91,7.91,0,0,1,13.26,5.25Z" fill="%23ea4335"/><path d="M1.41,7.31a13.28,13.28,0,0,0,0,11.91L5.82,15.8a7.86,7.86,0,0,1,0-5.07Z" fill="%23fbbc04"/><path d="M17.78,20a8,8,0,0,1-12-4.21h0L1.41,19.22a13.26,13.26,0,0,0,11.85,7.3,12.66,12.66,0,0,0,8.79-3.2Z" fill="%2334a853"/></svg>');
      }
    }

    &.btn-primary.facebook {
      &::before {
        position: absolute;
        top: rem(13);
        left: rem(26);
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><circle cx="11" cy="11" r="11" fill="%23fff"/><path d="M22,11A11,11,0,1,0,11,22h.2V13.4H8.8V10.7h2.4v-2c0-2.4,1.4-3.7,3.5-3.7h2.1V7.6H15.4c-1.1,0-1.4.5-1.4,1.3v1.8h2.8l-.4,2.7H14v8.2A11.1,11.1,0,0,0,22,11Z" fill="%231977f2"/></svg>');
      }
    }

    &.btn-secondary.facebook {
      &::before {
        position: absolute;
        top: rem(13);
        left: rem(26);
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><circle cx="11" cy="11" r="11" fill="%23fff"/><path d="M22,11A11,11,0,1,0,11,22h.2V13.4H8.8V10.7h2.4v-2c0-2.4,1.4-3.7,3.5-3.7h2.1V7.6H15.4c-1.1,0-1.4.5-1.4,1.3v1.8h2.8l-.4,2.7H14v8.2A11.1,11.1,0,0,0,22,11Z" fill="%231977f2"/></svg>');
      }
    }
  }

  &.btn-icon-md {
    position: relative;
    width: rem(116);
    height: auto;
    padding: rem(15) rem(11) rem(15) rem(21);
    font-size: rem(13);
    line-height: rem(16);
    text-align: left;

    &.btn-primary {
      &::after {
        position: absolute;
        top: rem(12);
        right: rem(10);
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" fill="%23ffffff" opacity="0.25"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%23ffffff" /></svg>');
      }
    }

    &.btn-secondary {
      &::after {
        position: absolute;
        top: rem(12);
        right: rem(10);
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" fill="%23ffffff" opacity="0.25"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%23ffffff" /></svg>');
      }
      &.previous {
        &::after {
          top: rem(5);
          transform: rotate(180deg);
        }
      }
    }
  }

  &.btn-icon-sm {
    position: relative;
    width: rem(82);
    height: auto;
    padding: rem(7) rem(11) rem(7) rem(11);
    font-size: rem(12);
    line-height: rem(12);
    text-align: left;

    &.btn-primary {
      &::after {
        position: absolute;
        top: rem(5);
        left: rem(58);
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" fill="%23ffffff" opacity="0.25"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%23ffffff" /></svg>');
      }
    }

    &.btn-secondary {
      &::after {
        position: absolute;
        top: rem(5);
        left: rem(58);
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" fill="%23ffffff" opacity="0.25"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%23ffffff" /></svg>');
      }
      &.previous {
        &::after {
          content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" opacity="0.25" style="isolation:isolate" fill="grey" /><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%27ffffff" /></svg>');
        }
      }
    }
  }

  // top nav buttons
  &.btn-link {
    text-decoration: underline;
  }
}

// *** Button icons ***
// These are anchor elements with pseudo elements
.back-link-icon {
  position: relative;
  z-index: 100;
  display: block;
  padding: 0 rem(10) 0 rem(30);
  color: $white;
  font-size: rem(12);
  line-height: rem(17);
  &:hover {
    text-decoration: none;
  }
}

.back-link-icon {
  &::before {
    position: absolute;
    top: rem(-2);
    left: rem(2);
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path fill="%23ffffff" d="M10.5,21A10.5,10.5,0,1,1,21,10.5,10.51,10.51,0,0,1,10.5,21Zm0-20A9.5,9.5,0,1,0,20,10.5,9.51,9.51,0,0,0,10.5,1Z"/><polygon fill="%23ffffff" points="15.65 10 7.61 10 10.5 6.75 9.75 6.09 5.83 10.5 9.75 14.91 10.5 14.25 7.61 11 15.65 11 15.65 10"/></svg>');
  }
}

.btn-label {
  position: relative;
  color: $steel;
  font-size: rem(13);
  line-height: rem(18);

  // todo: finish styling
  &::after {
    position: absolute;
    top: rem(-20);
    left: rem(10);
    // content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11.74" height="15.65" viewBox="0 0 11.74 15.65"><path d="M9.74,2V12.09L6.89,10.36l-1-.63-1,.63L2,12.08V2H9.74m2-2H0V15.65l5.85-3.58,5.89,3.58V0Z"/></svg>');
  }
}

.clear-link-icon {
  position: relative;
  color: $white;
  font-size: rem(13);
  line-height: rem(18);

  // todo: finish styling
  &::after {
    position: absolute;
    top: rem(3);
    left: rem(40);
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path fill="%23ffffff" d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.51,10.51,0,0,0,10.5,0Zm0,20A9.5,9.5,0,1,1,20,10.5,9.51,9.51,0,0,1,10.5,20ZM14.65,5.65,10.5,9.79,6.35,5.65l-.7.7L9.79,10.5,5.65,14.65l.7.7,4.15-4.14,4.15,4.14.7-.7L11.21,10.5l4.14-4.15Z"/></svg>');
  }
}

.close-link {
  .close-link-text {
    display: inline-block;
    margin-left: 1.7rem;
    line-height: rem(22);
    @include breakpoint(md) {
      display: none;
    }
  }
  .close-link-icon {
    position: absolute;
    left: rem(-4);
    z-index: 101;
    display: block;
    width: rem(21);
    height: rem(21);
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.51,10.51,0,0,0,10.5,0Zm0,20A9.5,9.5,0,1,1,20,10.5,9.51,9.51,0,0,1,10.5,20ZM14.65,5.65,10.5,9.79,6.35,5.65l-.7.7L9.79,10.5,5.65,14.65l.7.7,4.15-4.14,4.15,4.14.7-.7L11.21,10.5l4.14-4.15Z" fill="%23000000"/></svg>')
      center center no-repeat;
    @include breakpoint(md) {
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="11.1" height="11.1" viewBox="0 0 11.1 11.1"><polygon points="11.1 1.4 9.7 0 5.6 4.1 1.4 0 0 1.4 4.1 5.6 0 9.7 1.4 11.1 5.6 7 9.7 11.1 11.1 9.7 7 5.6 11.1 1.4" fill="black"/></svg>')
        center center no-repeat;
    }
  }
  &.white {
    .close-link-text {
      color: $white;
    }
    .close-link-icon {
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.51,10.51,0,0,0,10.5,0Zm0,20A9.5,9.5,0,1,1,20,10.5,9.51,9.51,0,0,1,10.5,20ZM14.65,5.65,10.5,9.79,6.35,5.65l-.7.7L9.79,10.5,5.65,14.65l.7.7,4.15-4.14,4.15,4.14.7-.7L11.21,10.5l4.14-4.15Z" fill="%23ffffff" /></svg>')
        center center no-repeat;
      @include breakpoint(md) {
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="11.1" height="11.1" viewBox="0 0 11.1 11.1"><polygon points="11.1 1.4 9.7 0 5.6 4.1 1.4 0 0 1.4 4.1 5.6 0 9.7 1.4 11.1 5.6 7 9.7 11.1 11.1 9.7 7 5.6 11.1 1.4" fill="%23ffffff"/></svg>')
          center center no-repeat;
      }
    }
  }
}

.theme-light {
  .btn {
    // solid black button
    &.btn-primary {
      color: $white;
      background-color: $black;
      border: 1px solid $black;
      box-shadow: rem(2) rem(2) rem(7) 0 rgba(0, 0, 0, 1);
      &:hover {
        background-color: $black-hover;
        border: 1px solid $black-hover;
      }
    }

    // transparent bg
    &.btn-secondary {
      color: $black;
      background-color: transparent;
      border: 1px solid $border-color;

      &:hover {
        color: $black;
        border: 1px solid $border-color-hover;
      }
    }

    // solid white button
    &.btn-ternary {
      color: $black;
      background-color: $white;
      border: 1px solid $white;
      box-shadow: rem(2) rem(2) rem(7) 0 rgba(0, 0, 0, 1);

      &:hover {
        background-color: $white-hover;
        border: 1px solid $white-hover;
      }
    }

    &.btn-signup {
      &:hover {
        color: white;
        background-color: white;
      }
    }

    .guest-signup,
    .account-signup {
      color: white;
    }

    &.btn-icon {
      color: $secondary-color;
      background-color: transparent;
      border: 1px solid $border-color;
    }

    &.btn-icon-lg {
      &.btn-primary.google {
        font-weight: normal;
        &::before {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26.52"><path d="M17.77,20h0l4.27,3.31A12.94,12.94,0,0,0,26,13.57a15.3,15.3,0,0,0-.23-2.7H13.26V16h7.17A6.16,6.16,0,0,1,17.77,20Z" fill="%234285f4"/><path d="M13.26,5.25a7.2,7.2,0,0,1,5.09,2l3.79-3.79A12.76,12.76,0,0,0,13.26,0,13.27,13.27,0,0,0,1.41,7.31l4.41,3.42A7.91,7.91,0,0,1,13.26,5.25Z" fill="%23ea4335"/><path d="M1.41,7.31a13.28,13.28,0,0,0,0,11.91L5.82,15.8a7.86,7.86,0,0,1,0-5.07Z" fill="%23fbbc04"/><path d="M17.78,20a8,8,0,0,1-12-4.21h0L1.41,19.22a13.26,13.26,0,0,0,11.85,7.3,12.66,12.66,0,0,0,8.79-3.2Z" fill="%2334a853"/></svg>');
        }
      }

      &.btn-secondary.google {
        font-weight: normal;
        &::before {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26.52"><path d="M17.77,20h0l4.27,3.31A12.94,12.94,0,0,0,26,13.57a15.3,15.3,0,0,0-.23-2.7H13.26V16h7.17A6.16,6.16,0,0,1,17.77,20Z" fill="%234285f4"/><path d="M13.26,5.25a7.2,7.2,0,0,1,5.09,2l3.79-3.79A12.76,12.76,0,0,0,13.26,0,13.27,13.27,0,0,0,1.41,7.31l4.41,3.42A7.91,7.91,0,0,1,13.26,5.25Z" fill="%23ea4335"/><path d="M1.41,7.31a13.28,13.28,0,0,0,0,11.91L5.82,15.8a7.86,7.86,0,0,1,0-5.07Z" fill="%23fbbc04"/><path d="M17.78,20a8,8,0,0,1-12-4.21h0L1.41,19.22a13.26,13.26,0,0,0,11.85,7.3,12.66,12.66,0,0,0,8.79-3.2Z" fill="%2334a853"/></svg>');
        }
      }

      &.btn-primary.facebook {
        font-weight: normal;
        &::before {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><circle cx="11" cy="11" r="11" fill="%23fff"/><path d="M22,11A11,11,0,1,0,11,22h.2V13.4H8.8V10.7h2.4v-2c0-2.4,1.4-3.7,3.5-3.7h2.1V7.6H15.4c-1.1,0-1.4.5-1.4,1.3v1.8h2.8l-.4,2.7H14v8.2A11.1,11.1,0,0,0,22,11Z" fill="%231977f2"/></svg>');
        }
      }

      &.btn-secondary.facebook {
        font-weight: normal;
        &::before {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><circle cx="11" cy="11" r="11" fill="%23fff"/><path d="M22,11A11,11,0,1,0,11,22h.2V13.4H8.8V10.7h2.4v-2c0-2.4,1.4-3.7,3.5-3.7h2.1V7.6H15.4c-1.1,0-1.4.5-1.4,1.3v1.8h2.8l-.4,2.7H14v8.2A11.1,11.1,0,0,0,22,11Z" fill="%231977f2"/></svg>');
        }
      }
    }

    &.btn-icon-md {
      &.btn-primary {
        &::after {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" fill="%23000000" opacity="0.25"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%23ffffff" /></svg>');
        }
      }

      &.btn-secondary {
        &::after {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" fill="%23000000" opacity="0.25"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%23ffffff" /></svg>');
        }
      }
    }

    &.btn-icon-sm {
      &.btn-primary {
        &::after {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" fill="%23ffffff" opacity="0.25"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%23ffffff" /></svg>');
        }
      }

      &.btn-secondary {
        &::after {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" fill="%23000000" opacity="0.25"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="%23ffffff" /></svg>');
        }
        &.previous {
          &::after {
            content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" opacity="0.25" style="isolation:isolate" fill="grey" /><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="white" /></svg>');
          }
        }
      }
    }

    // top nav buttons
    &.btn-link {
      text-decoration: underline;
    }
  }

  // *** Button icons ***
  // These are anchor elements with pseudo elements
  .back-link-icon,
  .close-link-icon {
    color: $black;
  }

  .back-link-icon {
    &::before {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path fill="%23ffffff" d="M10.5,21A10.5,10.5,0,1,1,21,10.5,10.51,10.51,0,0,1,10.5,21Zm0-20A9.5,9.5,0,1,0,20,10.5,9.51,9.51,0,0,0,10.5,1Z"/><polygon fill="%23ffffff" points="15.65 10 7.61 10 10.5 6.75 9.75 6.09 5.83 10.5 9.75 14.91 10.5 14.25 7.61 11 15.65 11 15.65 10"/></svg>');
    }
  }

  .close-link-icon {
    &::after {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><path fill="%23ffffff" d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.51,10.51,0,0,0,10.5,0Zm0,20A9.5,9.5,0,1,1,20,10.5,9.51,9.51,0,0,1,10.5,20ZM14.65,5.65,10.5,9.79,6.35,5.65l-.7.7L9.79,10.5,5.65,14.65l.7.7,4.15-4.14,4.15,4.14.7-.7L11.21,10.5l4.14-4.15Z"/></svg>');
    }
  }

  .btn-label {
    color: $steel;
    // todo: finish styling
    &::after {
      // content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11.74" height="15.65" viewBox="0 0 11.74 15.65"><path d="M9.74,2V12.09L6.89,10.36l-1-.63-1,.63L2,12.08V2H9.74m2-2H0V15.65l5.85-3.58,5.89,3.58V0Z"/></svg>');
    }
  }

  .clear-link-icon {
    color: $steel;
    // todo: finish styling
    &::after {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><circle cx="6" cy="6" r="6" fill="%2380838e"/><path d="M8.37,3.23,6,5.6,3.63,3.23l-.4.4L5.6,6,3.23,8.37l.4.4L6,6.4,8.37,8.77l.4-.4L6.4,6,8.77,3.63Z" fill="%23ffffff"/></svg>');
    }
  }
}

.save-button,
.next-button {
  display: block;
  width: rem(37);
  height: auto;
  // overflow: hidden;
  color: black;
  transform: translateX(-44%);
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  span {
    margin: rem(-4) 0 0 0;
    font-size: rem(12);
  }
  &.hide {
    visibility: hidden;
    opacity: 0;
  }
  &.next-button {
    display: none;
    height: rem(37);
    span {
      visibility: hidden;
    }
    @include breakpoint(md) {
      display: block;
    }
  }

  .progress-ring {
    position: absolute;
    z-index: 0;

    .progress-ring__circle {
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      transition: stroke-dashoffset 0.35s;
    }

    .progress-ring__empty-circle {
      opacity: 0.1;
    }
  }

  .save-icon {
    display: inline-block;
    width: rem(37);
    height: rem(37);
    background-image: url("data:image/svg+xml,%3Csvg width='37' height='37' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='19' cy='19' r='18' fill='none' stroke='%23000' stroke-opacity='.2' stroke-linecap='square'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.09 12.454h9.606V25.26l-4.819-2.929-4.786 2.93V12.453zm7.97 9.892v-8.255h-6.333v8.247l2.365-1.407.818-.515.818.515 2.332 1.415z' fill='%23000'/%3E%3C/svg%3E");
    // bottom center no-repeat;
  }
  .save-text {
    display: block;
    @include breakpoint(md) {
      display: none;
    }
  }
  .next-icon {
    z-index: 100;
    display: inline-block;
    width: rem(37);
    height: rem(37);
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7"><polygon points="12 0 0 0 6 7 12 0" fill="grey"/></svg>');
    background-repeat: no-repeat;
    background-position: center 52%;
    // border: 1px solid grey;
    // border-radius: 50%;
    transition: 0.3s;
    &.active {
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7"><polygon points="12 0 0 0 6 7 12 0" fill="white"/></svg>')
        center center no-repeat;
    }
  }
  &.active {
    .save-icon {
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 37 37"><path d="M18.5,0A18.5,18.5,0,1,0,37,18.5,18.52,18.52,0,0,0,18.5,0Z" fill="black"/><polygon points="12.63 26.33 18.48 22.74 24.37 26.33 24.37 10.67 12.63 10.67 12.63 26.33" fill="white"/></svg>')
        bottom center no-repeat;
    }
  }
}

.next-button {
  span {
    font-weight: 500;
  }
}

.save-button {
  span {
    font-weight: 400;
  }
}

.mobile-controls {
  display: none;

  @include breakpoint(md) {
    display: flex;
  }

  > .save-button {
    @include breakpoint(md) {
      margin-left: rem(16);
    }
  }
}
