.saved-modules {
  max-width: rem(460);

  .saved-modules-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
      color: white;
      font-weight: bold;
      font-size: rem(24);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: rem(-0.25);
    }

    .edit-button {
      position: absolute;
      top: 0;
      right: 0;
      color: white;
      font-weight: normal;
      font-size: rem(13);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .saved-parent {
    color: white;
    font-weight: normal;
    font-size: rem(13);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .saved-module-container {
    margin-bottom: rem(27);

    .no-modules {
      color: $steel;
      font-weight: normal;
      font-size: rem(13);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .saved-module {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: rem(460);
      height: rem(66);
      margin: rem(5) 0 rem(7) 0;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 6px;
      box-shadow: rem(12) 0 rem(25) 0 rgba(0, 0, 0, 0.25);
      transition: 0.3s;

      @media (max-width: $size-sm) {
        width: 100%;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
        box-shadow: rem(12) 0 rem(25) 0 rgba(0, 0, 0, 0.25);
        cursor: pointer;
      }

      .module-type-image {
        width: rem(50);
        height: rem(40);
        margin: rem(13);
        background: grey;
      }

      .module-link-image {
        min-width: rem(66);
        height: rem(66);
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10"><polygon fill="white" points="1.2 8 0 6.8 2.8 4 0 1.2 1.2 0 5.2 4 1.2 8"/></svg>')
          center center no-repeat;
        transition: 0.3s;

        &.edit {
          background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="11.1" height="11.1" viewBox="0 0 11.1 11.1"><polygon fill="white" points="11.1 1.4 9.7 0 5.6 4.1 1.4 0 0 1.4 4.1 5.6 0 9.7 1.4 11.1 5.6 7 9.7 11.1 11.1 9.7 7 5.6 11.1 1.4"/></svg>')
            center center no-repeat;
        }
      }
    }

    .module-info-container {
      display: flex;
      flex-direction: row;
    }

    .module-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        margin: 0 0 rem(3) 0;
        color: white;
        font-weight: bold;
        font-size: rem(13);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
      }

      h4 {
        display: inline;
        margin: rem(3) rem(4) rem(2) 0;
        color: white;
        font-weight: normal;
        font-size: rem(12);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: rem(0.25);
      }

      h4 .module-type {
        display: inline;
        color: $bluey-grey;
        font-weight: normal;
        font-size: rem(12);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: rem(0.25);
        text-transform: capitalize;
      }
    }
  }
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
