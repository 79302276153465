.reflection-question {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100vh;
  max-height: -webkit-fill-available;
  scroll-snap-align: start;

  @include breakpoint(md) {
    width: 100%;
  }

  .mChoiceForm {
    width: rem(780);
    max-width: 90%;

    @include breakpoint(xxs) {
      margin-top: rem(-40);
    }

    p {
      margin-bottom: rem(36);
      color: $black;
      font-weight: normal;
      font-size: rem(18);
      font-family: $serif-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;

      @include breakpoint(xxs) {
        margin-bottom: rem(10);
      }

      @media only screen and (min-width: $size-md) {
        font-size: rem(24);
      }
      @media only screen and (min-width: $size-lg) {
        font-size: rem(32);
      }
    }

    .input-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      margin-bottom: rem(21);

      &.selected.hide-true {
        display: flex;
      }

      input {
        width: rem(26);
        height: rem(26);
        margin-right: rem(11);
        border: solid 1px $black;
        border-radius: 38px;
        transition: 0.3s;
        appearance: none;

        &:checked {
          background-color: var(--section-colour);
          border: solid 1px var(--section-colour);
        }
      }

      label {
        margin: 0;
        color: $black;
        font-weight: bold;
        font-size: rem(24);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: rem(-0.25);
      }

      .addNode {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    textarea {
      height: rem(70);
      padding: 0;
      color: $black;
      font-weight: normal;
      font-size: rem(16);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: rem(28);
      letter-spacing: normal;
      outline: none;

      @include breakpoint(xxs) {
        margin-bottom: rem(5);
      }

      &::placeholder {
        color: $steel;
        font-weight: normal;
        font-size: rem(13);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        opacity: 0.6;
      }

      @media (min-width: $size-sm) {
        height: rem(100);
        font-size: rem(16);
      }
    }

    .add-written {
      display: block;
      margin-bottom: rem(34);
      color: $steel;
      font-weight: normal;
      font-size: rem(13);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .submit:not([disabled]) {
        box-shadow: rem(5) rem(10) rem(25) 0 rgba(128, 131, 142, 0.4);
      }
    }

    .submit {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: rem(126);
      height: rem(42);
      padding: rem(9) rem(9) rem(9) rem(9);
      color: $white;
      background-color: var(--section-colour);
      border-radius: rem(24);
      transition: 0.3s;

      .next-arrow {
        display: inline-block;
        width: rem(23);
        height: rem(23);
        margin-left: rem(12);
        background: url('../../assets/dark/arrow-circle-fill-right.svg') center center no-repeat;
      }

      &:disabled {
        background-color: #cccdd2;
      }
    }

    .clear {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: rem(30);
      color: $steel;
      font-weight: normal;
      font-size: rem(13);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;

      .clear-x {
        display: inline-block;
        width: rem(12);
        height: rem(12);
        margin-left: rem(5);
        background: url('../../assets/dark/close-circle-fill.svg') center center no-repeat;
      }
    }
  }
}

.display-enter {
  opacity: 0;
}
.display-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.display-exit {
  opacity: 1;
}
.display-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
