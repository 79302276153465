/* stylelint-disable */
// Core variables
$version: '0.5.9';

// Core features
$rtl: false;

// Core colours
$white: #fff;
$white-hover: #dadadc;
$black: #000;
$black-hover: #232323;

$dark-purple: #01020f;
$watermelon: #ff4a69;
$watermelon-dark: #de2948;
$periwinkle: #977fff;
$periwinkle-dark: #765dde;
$sickley-yellow: #d5db41;
$sickley-yellow-dark: #777a21;
$orangey-red: #fb5927;
$orangey-red-dark: #db3a08;
$tiffany-blue: #86e3ed;
$tiffany-blue-dark: #217e88;

$learning-module-bg-pink: #fff6f7;

// Gray colours
$dark-gray: #979797;
$steel: #80838e;
$slate-grey: #53545c;
$bluey-grey: #9b9da6;

// Borders
$border-color-dark: rgba(255, 255, 255, 0.35);
$border-color-dark-hover: $white;

$border-color: rgba(0, 0, 0, 0.35);
$border-color-hover: $black;

// links
$link-color: $black;
$link-color-dark: darken($link-color, 10%);
$link-color-light: lighten($link-color, 10%);

// backgrounds
$bg-color: $white;
$bg-color-dark: #0f1224;

//Menu colours
$bg-menu-color: rgba(0, 0, 0, 0.75);

// Control colours
$success-color: #32b643;
$warning-color: #ffb700;
$error-color: #e85600;

// Other colours
$code-color: #d73e48;
$highlight-color: #ffe9b3;
$body-font-color: $black;
$animation-play-button: #977fff;

// Fonts
// Credit: https://www.smashingmagazine.com/2015/11/using-system-ui-fonts-practical-guide/
$base-font-family: 'Poppins', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto;
$mono-font-family: 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', Menlo, Courier, monospace;
$fallback-font-family: 'Helvetica Neue', sans-serif;
$body-font-family: $base-font-family, $fallback-font-family;
$serif-font-family: 'Nanum Myeongjo', serif;
$quote-font-family: 'Reenie Beanie', serif;

// Unit sizes
$unit-o: 0.05rem;
$unit-h: 0.1rem;
$unit-1: 0.2rem;
$unit-2: 0.4rem;
$unit-3: 0.6rem;
$unit-4: 0.8rem;
$unit-5: 1rem;
$unit-6: 1.2rem;
$unit-7: 1.4rem;
$unit-8: 1.6rem;
$unit-9: 1.8rem;
$unit-10: 2rem;
$unit-12: 2.4rem;
$unit-16: 3.2rem;

// Font sizes
$html-font-size: $html-base-font-size; // base font size
$html-line-height: 1.5;
$font-size-xs: rem(10);
$font-size: rem(12);
$font-size-sm: rem(13);
$font-size-md: rem(14);
$font-size-lg: rem(18);
$line-height: 1.2rem;

// Sizes
$layout-spacing: $unit-2;
$layout-spacing-sm: $unit-1;
$layout-spacing-lg: $unit-4;
$border-radius: $unit-h;
$border-width: $unit-o;
$border-width-lg: $unit-h;
$control-size: $unit-9;
$control-size-sm: $unit-7;
$control-size-lg: $unit-10;
$control-padding-x: $unit-2;
$control-padding-x-sm: $unit-2 * 0.75;
$control-padding-x-lg: $unit-2 * 1.5;
$control-padding-y: ($control-size - $line-height) / 2 - $border-width;
$control-padding-y-sm: ($control-size-sm - $line-height) / 2 - $border-width;
$control-padding-y-lg: ($control-size-lg - $line-height) / 2 - $border-width;
$control-icon-size: 0.8rem;

$control-width-xs: 180px;
$control-width-sm: 320px;
$control-width-md: 640px;
$control-width-lg: 960px;
$control-width-xl: 1280px;

$control-width-xs: 180px;
$control-width-sm: 320px;
$control-width-md: 640px;
$control-width-lg: 960px;
$control-width-xl: 1280px;

// Responsive breakpoints
$size-xxs: 360px; // 0-360px
$size-xs: 374px; // 361px-480px
$size-sm: 600px; // 481px-600px
$size-md: 840px; // 601-840px
$size-lg: 960px; // 841px-960px
$size-xl: 1280px; // 961px-1280px
$size-xxl: 1450px; // (base) 1281px and up

// Z-index
$zindex-0: 1;
$zindex-1: 100;
$zindex-2: 200;
$zindex-3: 300;
$zindex-4: 400;
/* stylelint-enable */
