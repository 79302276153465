.menu-closed,
.menu-open {
  width: rem(224);
  color: $secondary-color;
  text-align: center;
  background: $bg-menu-color;
}

.menu-closed {
  height: rem(224);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  transform: rotate(90deg);
  -moz-clip-path: circle(50% at 0%);
  -webkit-clip-path: circle(50% at 0%);
  clip-path: circle(50% at 0%);
  shape-outside: circle(50% at 30%);
}

.menu-open {
  height: rem(71);
  -moz-border-radius: rem(16) rem(99) rem(14);
  -webkit-border-radius: rem(16) rem(99) rem(14);
  border-radius: rem(16) rem(99) rem(14);
}
