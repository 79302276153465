#root {
  height: 100vh;
  height: -webkit-fill-available;

  > .main-container {
    position: relative;
    top: 0;
    height: 100vh;
    max-height: -webkit-fill-available;
  }
}

.skip-to-nav-link {
  position: absolute;
}

.page {
  width: 100%;
  height: 100%;
}

.page-enter {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: rem(1);
  height: rem(1);
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}
