.page-not-found {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(#000, #04082f);

  .auth-navigation {
    top: 0;
    @media (max-width: $size-md) {
      padding: rem(40);
    }
  }
  h1 {
    width: fit-content;
    color: white;
    font-weight: bold;
    font-size: rem(24);
    font-family: $base-font-family;
    line-height: 1.25;
    letter-spacing: rem(-0.25);
    text-align: center;
  }
  p {
    color: #9b9da6;
    font-weight: normal;
    font-size: rem(13);
    line-height: normal;
    letter-spacing: normal;
  }

  .btn {
    max-width: rem(225);
  }
}
