.quote-module {
  .module-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
  }

  .body-text {
    top: rem(350);

    @include breakpoint(md) {
      top: rem(300);
    }
    @include breakpoint(sm) {
      top: rem(450);
    }
  }

  .quote-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: rem(1070);
    padding-left: rem(60);
    transform: rotateZ(-4deg);
    svg {
      overflow: visible;
    }
  }

  .play-button-container-enter {
    transform: scale(0.9);
    opacity: 0;
  }
  .play-button-container-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: opacity 1000ms, transform 1000ms;
  }
  .play-button-container-exit {
    opacity: 1;
  }
  .play-button-container-exit-active {
    transform: scale(0.9);
    opacity: 0;
    transition: opacity 1000ms, transform 1000ms;
  }

  .instruction-text {
    margin-bottom: rem(16);
    font-weight: normal;
    font-size: rem(13);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
  }
}
