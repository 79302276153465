.module.learning-module-ani.animation-module.in-view {
  .player_container {
    position: absolute;
    top: 50%;
    right: rem(40);
    display: none;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-50%);

    @media only screen and (max-width: $size-md) {
      right: rem(45);
    }
    @media only screen and (max-width: $size-sm) {
      top: initial;
      right: rem(65);
      bottom: rem(60);
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
    }

    &.show_player {
      z-index: 7;
      display: flex;
      .pause_btn {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M19,30h4V18H19Zm6-12V30h4V18Z" fill="white"/></svg>')
          no-repeat;
      }
      .play_btn {
        background: none;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.2 12"><polygon points="0 12 0 0 10.2 6 0 12" fill="white"/></svg>')
          center center no-repeat;
        background-position-x: 55%;
        background-size: rem(12);
      }
      .pause_btn,
      .play_btn {
        overflow: hidden;
        text-indent: rem(-999);
        background-color: var(--section-colour);
        &:hover {
          background-color: var(--section-colour-dark);
        }
        @media only screen and (max-width: $size-sm) {
          margin-bottom: rem(10);
        }
      }
    }
    .reset_btn {
      top: 60%;
      overflow: hidden;
      text-indent: rem(-999);
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g><circle cx="24" cy="24" r="24"/></g><path d="M31.62,18.45,29,20.69A6,6,0,1,1,23.37,18v3.33l6-5.05-6-5v3.3a9.39,9.39,0,0,0-8.58,7.32,9.5,9.5,0,0,0,5.12,10.6,9.36,9.36,0,0,0,11.47-2.63A9.5,9.5,0,0,0,31.62,18.45Z" fill="white"/></svg>')
        center center no-repeat;
      background-size: rem(40);
      opacity: 0.25;
      &:hover {
        opacity: 0.5;
      }
      @media only screen and (max-width: $size-sm) {
        top: unset;
        bottom: 0;
        left: 35%;
      }
    }
    .pause_play,
    .reset_btn {
      width: rem(40);
      height: rem(40);
      margin: 0;
      margin-bottom: rem(10);
      border-radius: 50%;
      pointer-events: all;
      @media only screen and (max-width: $size-sm) {
        margin: 0 rem(6);
      }
    }
  }
  .svg_animation {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100vh;
    max-height: -webkit-fill-available;
    text-align: center;
    pointer-events: none;
    .svgObject {
      position: absolute;
      top: 45%;
      right: 0;
      left: 0;
      max-width: rem(920);
      margin-right: auto;
      margin-left: auto;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      @media only screen and (max-width: $size-sm) {
        top: 15%;
        left: 50%;
        min-width: rem(600);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
    .play_stop {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @media only screen and (max-width: $size-sm) {
        top: rem(-15);
      }
      &.hide_bg {
        opacity: 0;
        transition: opacity 0.5s ease-out;
        .play_anim {
          pointer-events: none;
        }
      }
      .play_anim {
        position: relative;
        top: 45%;
        width: rem(80);
        height: rem(80);
        padding: rem(30) rem(30) rem(30) rem(33);
        overflow: hidden;
        text-indent: rem(-999);
        background-color: var(--section-colour);
        border-radius: 50%;
        pointer-events: all;
        &:hover {
          background-color: var(--section-colour-dark);
        }
      }
      .play_anim::after {
        position: absolute;
        top: calc(50% - 0.8125rem);
        right: 0;
        left: rem(30);
        width: rem(25);
        height: rem(25);
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.2 12"><polygon points="0 12 0 0 10.2 6 0 12" fill="white"/></svg>')
          center center no-repeat;
        content: '';
      }
    }
  }
  .quote-citation {
    margin-bottom: rem(65);
    padding-top: 0;
  }
  .animation-intro-container {
    position: absolute;
    top: rem(130);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    &.hide_intro {
      opacity: 0;
      transition: opacity 0.5s ease-out;
    }
  }
  .animation-intro-title {
    margin-bottom: rem(5);
    color: black;
    font-weight: bold;
    font-size: rem(13);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    @include breakpoint(md) {
      max-width: 80%;
    }
  }
  .animation-intro {
    color: black;
    font-weight: bold;
    font-size: rem(15);
    font-family: $serif-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    @include breakpoint(md) {
      max-width: 80%;
    }
  }
}

// Background Image with Play button
#slow-song {
  .svg_animation {
    .svgObject {
      top: 50%;
      @media only screen and (max-width: $size-sm) {
        top: 15%;
      }
    }
  }
  .play_stop {
    background: url('../../assets/images/bg_slow_song.png') center center no-repeat;
  }
}

#knowing-hands {
  .play_stop {
    background: url('../../assets/images/bg_knowing_hands.png') center center no-repeat;
  }
}

#string-of-pearls {
  .play_stop {
    background: url('../../assets/images/bg_string_of_pearls.png') center center no-repeat;
  }
}

#alive {
  .svg_animation {
    .svgObject {
      top: 60%;
      @media only screen and (max-width: $size-sm) {
        top: 25%;
      }
    }
  }
  .play_stop {
    width: rem(300);
    background: url('../../assets/images/bg_alive.png') center center no-repeat;
    @media only screen and (max-width: $size-sm) {
      width: rem(200);
    }
  }
}

#toothpaste {
  .svg_animation {
    .svgObject {
      top: 55%;
      @media only screen and (max-width: $size-sm) {
        top: 10%;
      }
    }
  }
  .play_stop {
    top: rem(75);
    width: rem(200);
    background: url('../../assets/images/bg_toothpaste.png') center center no-repeat;
  }
}

#slow-song,
#knowing-hands,
#string-of-pearls,
#toothpaste,
#alive {
  // position: relative;
  // height: 100vh;
  .container_animation {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    max-height: -webkit-fill-available;
    padding: 0;
    overflow: hidden;
    .button-container {
      @include breakpoint(sm) {
        top: auto;
        right: rem(19);
        bottom: rem(85);
        z-index: 1000;
        display: flex;
        width: auto;
        transform: translateY(0);
      }
    }
    &.position_citation_btn {
      .button-container {
        /* stylelint-disable */
        top: calc(50% + 75px);
        /* stylelint-enable */
        z-index: 1000;
        @include breakpoint(sm) {
          top: auto;
          transform: translateY(0);
        }
      }
    }
  }
  .play_stop {
    max-width: rem(700);
    margin: 0 auto;
    background-size: contain;
  }
}
