.video-module {
  .module-wrapper {
    overflow: visible;

    .video-intro-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .video-intro-title {
      margin-bottom: rem(5);
      color: black;
      font-weight: bold;
      font-size: rem(13);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      @include breakpoint(md) {
        max-width: 80%;
      }
    }
    .video-intro {
      color: black;
      font-weight: bold;
      font-size: rem(15);
      font-family: $serif-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.27;
      letter-spacing: normal;
      text-align: center;
      @include breakpoint(md) {
        max-width: 80%;
      }

      p {
        max-width: rem(820);
        height: auto;
      }
    }

    .video-holder {
      position: relative;
      max-width: rem(820);
      height: auto;
      object-fit: contain;
      background: black;

      @media (max-width: rem(1200)) and (max-height: rem(850)) {
        max-width: rem(720);
      }

      @media (max-height: rem(800)) {
        max-width: rem(605);
      }

      @media (max-height: rem(600)) {
        max-width: rem(410);
      }

      .place-holder {
        position: relative;
        display: block;
        width: 100%;
        box-shadow: 0 rem(30) rem(45) 0 rgba(128, 131, 142, 0.4);
      }
      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(128, 131, 142, 0.4);
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 rem(30) rem(45) 0 rgba(128, 131, 142, 0.4);
      }
    }
    .play-button {
      position: relative;
      display: block;
      background: transparent;
      border: none;
      cursor: pointer;

      .video-play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(80);
        height: rem(80);
        padding-left: rem(5);
        color: white;
        font-size: rem(32);
        line-height: rem(80);
        background: var(--section-colour);
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
      &.hide-placeholder {
        visibility: hidden;
      }
    }
  }
}
