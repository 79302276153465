.loadingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .headphones {
    display: inline-block;
    width: rem(40);
    height: rem(40);
    margin-bottom: rem(15);
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill="white" d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,38A18,18,0,1,1,38,20,18,18,0,0,1,20,38Zm8.62-18.64V18a8.62,8.62,0,0,0-17.24,0v1.36A4.49,4.49,0,0,0,14,27.5v-9a4.54,4.54,0,0,0-1.38.24V18a7.38,7.38,0,1,1,14.76,0v.74A4.54,4.54,0,0,0,26,18.5v9a4.49,4.49,0,0,0,2.62-8.14Z"/></svg>')
      center center no-repeat;
  }

  p {
    width: rem(135);
    height: rem(38);
    margin-bottom: rem(52);
    font-size: rem(13);
    font-family: $base-font-family;
    text-align: center;
  }

  .loadingPercent {
    font-weight: 200;
    font-size: rem(45);
    font-family: $base-font-family;
    text-align: center;
  }
}

.page.topics {
  .headphones {
    display: inline-block;
    width: rem(40);
    height: rem(40);
    margin-bottom: rem(15);
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill="black" d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,38A18,18,0,1,1,38,20,18,18,0,0,1,20,38Zm8.62-18.64V18a8.62,8.62,0,0,0-17.24,0v1.36A4.49,4.49,0,0,0,14,27.5v-9a4.54,4.54,0,0,0-1.38.24V18a7.38,7.38,0,1,1,14.76,0v.74A4.54,4.54,0,0,0,26,18.5v9a4.49,4.49,0,0,0,2.62-8.14Z"/></svg>')
      center center no-repeat;
  }
}

.parent-topic-container-loader {
  .loadingScreen {
    color: white;
    .headphones {
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill="white" d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,38A18,18,0,1,1,38,20,18,18,0,0,1,20,38Zm8.62-18.64V18a8.62,8.62,0,0,0-17.24,0v1.36A4.49,4.49,0,0,0,14,27.5v-9a4.54,4.54,0,0,0-1.38.24V18a7.38,7.38,0,1,1,14.76,0v.74A4.54,4.54,0,0,0,26,18.5v9a4.49,4.49,0,0,0,2.62-8.14Z"/></svg>')
        center center no-repeat;
    }
  }
}
