.text-module {
  position: relative;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text-wrapper {
    background: transparent;

    .body-text {
      top: 115%;
    }

    @media (max-width: $size-sm) {
      .body-text {
        top: 65%;
      }
    }
  }
  .text-wrap {
    display: grid;
    grid-column-gap: 0;
    width: 100%;
    .item-wrap {
      position: relative;
      display: flex;
      grid-row: 1;
      grid-column: 1;
      align-items: center;
      justify-content: center;
      width: 100%;
      opacity: 0;
      transition: opacity 2s ease-in;
      &.current {
        opacity: 1;
        ::selection {
          background: transparent;
        }
        ::-moz-selection {
          background: transparent;
        }
      }
      .text {
        width: 80%;
        max-width: rem(1000);
        font-size: rem(32);
        line-height: normal;
        text-align: center;

        @include breakpoint(xxl) {
          max-width: rem(860);
          font-size: rem(24);
        }

        @include breakpoint(xl) {
          max-width: rem(605);
        }

        @include breakpoint(md) {
          max-width: rem(505);
          font-size: rem(18);
        }
      }
    }
  }

  .citation-ani {
    opacity: 0;
    transition: opacity 2000ms;
  }

  .citation-ani-enter {
    opacity: 0;
  }
  .citation-ani-active {
    opacity: 1;
  }
  .citation-ani-enter-done {
    opacity: 1;
  }
  .citation-ani-exit {
    opacity: 1;
  }
  .citation-ani-exit-active {
    opacity: 0;
  }
  .citation-ani-exit-done {
    opacity: 0;
  }
}
