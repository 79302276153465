.intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: -webkit-fill-available;
  padding-top: rem(100);
  color: white;

  h1 {
    margin-bottom: rem(20);
    text-align: center;
  }

  .next,
  .containue {
    opacity: 1;
    transition: 0.3s;

    &:disabled {
      opacity: 0;
    }
  }

  .btn.previous {
    color: white;
    border: none;
    opacity: 1;
    transition: 0.3s;
  }

  .btn.previous.disabled {
    border: none;
    opacity: 0.2;
  }
}

.intro-controls {
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: rem(400);
  transition: 0.3s;

  &.display {
    opacity: 1;
  }

  &.hide {
    opacity: 0;

    &.remove {
      display: none;
    }
  }

  @media (max-width: $size-md) {
    width: 75%;
  }

  .btn {
    min-width: rem(100);
    transition: 0.3s;
    &.hide {
      opacity: 0;

      @media (max-width: $size-md) {
        min-width: rem(10);
      }
    }
  }

  #previous,
  #continue {
    min-width: fit-content;
    padding: 0;
  }

  #previous {
    margin-right: rem(20);
  }
  #continue {
    margin-right: rem(52);
  }
}

.animationContainer {
  position: relative;
  z-index: 5;
  display: block;
  width: 100%;
  max-width: rem(934);
  height: 100%;
  max-height: rem(150);
  margin-top: rem(30);
  margin-bottom: rem(50);
  text-align: center;

  @media (max-width: $size-sm) {
    max-height: rem(250);
  }

  @media (max-width: $size-xs) {
    max-height: rem(300);
  }

  .intro-text {
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin-bottom: 0;
      transition: 1s;

      @include breakpoint(sm) {
        font-size: rem(20);
      }

      &.display {
        opacity: 1;
      }
      &.hide {
        opacity: 0;
      }
    }
    .text-xl {
      padding: 0 rem(25);
    }
  }
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.intro-container-ani {
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (min-width: $size-sm) {
    justify-content: center;
  }
  .visuallyhidden {
    position: absolute;
    width: rem(1);
    height: rem(1);
    margin: rem(-1);
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
  }
  .visuallyhidden.focusable:active,
  .visuallyhidden.focusable:focus {
    position: static;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

.intro-container-ani-enter {
  opacity: 0;
}
.intro-container-ani-exit {
  opacity: 1;
}
.intro-container-ani-enter-active {
  opacity: 1;
}
.intro-container-ani-exit-active {
  opacity: 0;
}
.intro-container-ani-enter-active,
.intro-container-ani-exit-active {
  transition: opacity 500ms;
}
