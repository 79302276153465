.module-image {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
}

.container-card {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  transform: translateY(-25%);
  transition: 0.3s;

  @include breakpoint(sm) {
    height: 80%;
    transform: translateY(0);
  }
}

.container-card ul {
  margin-block-end: 0;
}

.container-card .card-stack {
  position: relative;
  width: rem(660);
  height: rem(358);
  margin: rem(20) auto;
  transition: 300ms linear all;

  @media (max-width: $size-md) {
    width: rem(495);
    height: rem(268);
  }

  @media (max-width: $size-sm) {
    width: rem(330);
    // height: rem(179);
    height: auto;
  }
}

.container-card .card-stack .buttons {
  position: absolute;
  top: 50%;
  left: 55%;
  z-index: 100;
  transition: all 0.1s ease;
}

button {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.image-container img {
  width: auto;
  max-width: 100%;
  height: rem(358);

  @media (max-width: $size-md) {
    width: auto;
    height: rem(268);
  }

  @media (max-width: $size-sm) {
    width: auto;
    height: rem(179);
  }
}

.body-text {
  position: absolute;
  top: 95%;
  left: 50%;
  width: 100%;
  color: black;
  transform: translateX(-50%);

  @include breakpoint(sm) {
    top: inherit;
    width: 98%;
  }

  .count,
  .instruction-text {
    margin-bottom: rem(16);
    font-weight: normal;
    font-size: rem(13);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
  }

  .description {
    font-weight: bold;
    font-size: rem(15);
    font-family: $serif-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    @include breakpoint(sm) {
      max-height: rem(110);
      -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
      mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
      overflow-y: scroll;
      word-break: break-word;
    }
  }

  @media (max-width: $size-md) {
    bottom: rem(-200);
  }

  @media (max-width: $size-sm) {
    top: rem(220);
    bottom: inherit;
  }
}

.card {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: transparent;
  border: none;
  transition: 200ms linear all;
}

.next-animate .image-container {
  animation: nextAnimate 1s;
}

.prev-animate .image-container {
  animation: prevAnimate 1s;
}

.card .image-container {
  transition: transform 1s ease-in-out;
}

.card:nth-child(2) .image-container {
  transform: rotate(8deg);
}

.card:nth-child(3) .image-container {
  transform: rotate(-6deg);
}

.card:nth-child(4) .image-container {
  transform: rotate(-8deg);
}

.card:last-child {
  transition: transform 2s linear;
}

.count,
.description {
  color: var(--color-content-text);
}

.container-card .card-stack .prev {
  right: auto;
  left: -5%;
}

.relationships {
  .logo {
    width: rem(40);
    height: rem(40);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><circle cx="20" fill="%23977fff" cy="20" r="20"/><polygon points="18.85 26.15 17 24.3 21.3 20 17 15.7 18.85 13.85 25 20 18.85 26.15" fill="%23FFF"/></svg>');
  }
}
.stigma {
  .logo {
    width: rem(40);
    height: rem(40);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><circle cx="20" fill="%23ff4a69" cy="20" r="20"/><polygon points="18.85 26.15 17 24.3 21.3 20 17 15.7 18.85 13.85 25 20 18.85 26.15" fill="%23FFF"/></svg>');
  }
}
.identity {
  .logo {
    width: rem(40);
    height: rem(40);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><circle cx="20" fill="%23d5db41" cy="20" r="20"/><polygon points="18.85 26.15 17 24.3 21.3 20 17 15.7 18.85 13.85 25 20 18.85 26.15" fill="%23FFF"/></svg>');
  }
}
.current-culture-of-dementia-care {
  .logo {
    width: rem(40);
    height: rem(40);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><circle cx="20" fill="%23fb5927" cy="20" r="20"/><polygon points="18.85 26.15 17 24.3 21.3 20 17 15.7 18.85 13.85 25 20 18.85 26.15" fill="%23FFF"/></svg>');
  }
}
.possibilities {
  .logo {
    width: rem(40);
    height: rem(40);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><circle cx="20" fill="%2386e3ed" cy="20" r="20"/><polygon points="18.85 26.15 17 24.3 21.3 20 17 15.7 18.85 13.85 25 20 18.85 26.15" fill="%23FFF"/></svg>');
  }
}

.container-card .prev .logo {
  transform: rotate(180deg);
}

.container-card .card-stack .next {
  right: -5%;
  left: auto;
}

.card-list {
  position: relative;
  width: auto;
  height: auto;
  margin-top: 0;
}

.container-card .card-stack .card-list li {
  position: absolute;
  right: 0;
  left: 0;
  height: rem(450);
  margin: 0 auto;
  text-align: center;
  list-style: none;
  background-color: transparent;

  @media (max-width: $size-md) {
    height: rem(337.5);
  }

  @media (max-width: $size-sm) {
    height: rem(225);
  }
}

.click-event {
  pointer-events: none;
}

// ANIMATIONS

@keyframes nextAnimate {
  0% {
    z-index: 1000;
    // transform: translateX(rem(0));
    opacity: 1;
  }

  100% {
    z-index: 1000;
    // transform: translateX(rem(400));
    opacity: 0;
  }
}

@keyframes prevAnimate {
  0% {
    // transform: translateX(rem(1000));
    opacity: 0;
  }

  100% {
    // transform: translateX(rem(0));
    opacity: 1;
  }
}
