/* stylelint-disable */
$html-base-font-size: 16px; // base font size
/* stylelint-enable */

// Functions
@import 'global/functions';

// Variables
@import 'global/variables'; // adjust override variables where possible

// CSS Framework
@import 'node_modules/spectre.css/src/spectre';

// Global
@import 'global/mixins';
@import 'global/modules';
@import 'global/fonts';
@import 'global/typography';
@import 'global/icons';
@import 'global/layout';
@import 'global/app';

@import 'global/styleguide';

// Component includes
@import 'components/button';
@import 'components/form';
@import 'components/alert';

@import 'components/menu';
@import 'components/globalMenuItem';
@import 'components/menuFooterNavigation';
@import 'components/authNavigation';
@import 'components/loadingSpinner';
@import 'components/userControls';
@import 'components/accountMenu';

@import 'components/videoModule';
@import 'components/animationModule';
@import 'components/imageGallery';
@import 'components/textModule';
@import 'components/reflectionQuestions';
@import 'components/audioModule';

@import 'components/defaultModal';
@import 'components/loading';
@import 'components/tooltip';
@import 'components/particles';

@import 'components/childTopicCompletion';
@import 'components/nextSteps';
@import 'components/completedModule';

@import 'components/savedModules';
@import 'components/quoteModule';

@import 'components/completionCertificate';

@import 'components/citationPopup';

// Pages
@import 'pages/standardContent';
@import 'pages/learningModules';
@import 'pages/auth';
@import 'pages/introContainer';
@import 'pages/dashboard';
@import 'pages/savedModules';
@import 'pages/settings';
@import 'pages/resources';
@import 'pages/loginOptions';
@import 'pages/about';
@import 'pages/noContent';
