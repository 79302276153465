.parent-completed-overlay {
  position: absolute;
  top: 42%;
  left: 50%;
  width: rem(660);
  max-width: 95%;
  height: rem(495);
  padding: 0 rem(53) 0 rem(53);
  background: white;
  border-radius: 6px;
  transform: translate(-50%, -50%);

  @media only screen and (max-height: rem(700)) {
    overflow-y: scroll;
  }

  @media only screen and (max-height: rem(600)) {
    top: rem(18);
    height: 85vh;
    transform: translate(-50%, 0);
  }

  @media only screen and (max-height: rem(500)) {
    top: rem(18);
    height: 80vh;
    transform: translate(-50%, 0);
  }

  .completed-popup {
    position: relative;
    height: 100%;

    .completed-checkmark {
      position: absolute;
      top: 0;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(95);
      height: rem(95);
      background-color: #ff4a69;
      border-radius: 50%;
      box-shadow: rem(5) rem(10) rem(25) 0 rgba(128, 131, 142, 0.4);
      transform: translate(-50%, -50%);

      @media only screen and (max-height: rem(700)) {
        position: relative;
        margin-top: rem(18);
        transform: translate(-50%, 0);
      }

      .image-container {
        width: rem(40);
        height: rem(48);

        .checkmark {
          width: rem(40);
          height: rem(48);
          background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="48" viewBox="0 0 13 10.76"><polygon points="10.86 0 4.38 6.48 2.14 4.24 0 6.38 4.38 10.76 13 2.14 10.86 0" fill="white" fill-rule="evenodd"/></svg>')
            center no-repeat;
        }
      }
    }

    .completed-module-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: rem(38);

      h1 {
        margin-top: rem(111);
        color: black;
        font-weight: bold;
        font-size: rem(24);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: rem(-0.25);
        text-align: center;

        @media only screen and (max-height: rem(700)) {
          margin-top: rem(18);
        }
      }

      .completed-description {
        color: darkgrey;
        font-weight: normal;
        font-size: rem(24);
        font-family: $serif-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
      }
    }

    .completed-share-container {
      margin-bottom: rem(38);

      h2 {
        color: black;
        font-weight: bold;
        font-size: rem(13);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
      }

      .share-links-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: black;

        .share-link {
          margin: 0 rem(6);
        }

        .share-linkedin {
          display: inline-block;
          width: rem(24);
          height: rem(24);
          background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0ZM7.8,16.63H5.12V8.57H7.8ZM6.46,7.47h0a1.53,1.53,0,0,1-1.08-.41,1.34,1.34,0,0,1-.4-1,1.36,1.36,0,0,1,.41-1,1.56,1.56,0,0,1,1.1-.4,1.53,1.53,0,0,1,1.09.4,1.4,1.4,0,0,1,.41,1,1.35,1.35,0,0,1-.41,1A1.57,1.57,0,0,1,6.46,7.47Zm11,9.16H14.78V12.32c0-1.09-.38-1.83-1.35-1.83a1.48,1.48,0,0,0-1.38,1,1.92,1.92,0,0,0-.09.66v4.5H9.29s0-7.3,0-8.06H12V9.71a2.68,2.68,0,0,1,2.42-1.33c1.76,0,3.08,1.15,3.08,3.63Z"/></svg>')
            center center no-repeat;
        }

        .share-twitter {
          display: inline-block;
          width: rem(24);
          height: rem(24);
          background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm5.022,8.577c.005.108.008.217.008.326a7.136,7.136,0,0,1-7.184,7.184h0a7.139,7.139,0,0,1-3.87-1.135,5,5,0,0,0,.6.035,5.067,5.067,0,0,0,3.136-1.08,2.53,2.53,0,0,1-2.359-1.754,2.567,2.567,0,0,0,.475.046A2.524,2.524,0,0,0,7.5,12.11,2.526,2.526,0,0,1,5.47,9.635V9.6a2.517,2.517,0,0,0,1.144.315,2.526,2.526,0,0,1-.782-3.37,7.173,7.173,0,0,0,5.205,2.638,2.487,2.487,0,0,1-.066-.576,2.526,2.526,0,0,1,4.368-1.727,5.059,5.059,0,0,0,1.6-.613,2.536,2.536,0,0,1-1.11,1.4,5.04,5.04,0,0,0,1.45-.4A5.154,5.154,0,0,1,16.022,8.577Z"/></svg>')
            center center no-repeat;
        }

        .share-facebook {
          display: inline-block;
          width: rem(24);
          height: rem(24);
          background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg"  width="22" height="22" viewBox="0 0 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve"><path d="M11,0C4.9,0,0,4.9,0,11s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0z M14.4,6.1h-1.7c-0.3,0-0.6,0.2-0.7,0.6c0,0,0,0.1,0,0.1v1.5 h2.3L14.1,11h-2v7.4H9.1V11H7.6V8.4H9V6.7c0-1.2,0.6-3.1,3.1-3.1h2.3V6.1z"/></svg>')
            center center no-repeat;
        }
      }
    }

    .completed-more-info-container {
      h2 {
        color: black;
        font-weight: bold;
        font-size: rem(13);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
      }

      .more-info-link-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .more-info-link {
          margin: 0 rem(5);
          color: darkgrey;
          font-weight: normal;
          font-size: rem(13);
          font-family: $base-font-family;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
        }
      }
    }

    .next-topic-overlay {
      position: absolute;
      bottom: rem(-65);
      left: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, 50%);

      @media only screen and (max-height: rem(700)) {
        position: relative;
        bottom: 0;
        margin-top: rem(36);
        padding-bottom: rem(36);
        transform: translate(-50%, 0);
      }

      h2 {
        margin-bottom: rem(10);
        color: white;
        font-weight: 600;
        font-size: rem(40);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.15;
        letter-spacing: rem(-1);
        white-space: nowrap;
        text-align: center;

        @media only screen and (max-height: rem(700)) {
          color: black;
        }
      }

      .next-button-overlay {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: auto;
        padding: rem(12) rem(20);
        color: black;
        font-weight: bold;
        font-size: rem(13);
        font-family: $base-font-family;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: center;
        background-color: #d5db41;
        border-radius: rem(24);
        box-shadow: rem(5) rem(10) rem(25) 0 rgba(0, 0, 0, 0.4);

        .next-arrow {
          width: rem(23);
          height: rem(23);
          margin-left: rem(12);
          background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 21 21"><path d="M0,10.5A10.5,10.5,0,1,1,10.5,21,10.5,10.5,0,0,1,0,10.5Z" opacity="0.25" style="isolation:isolate"/><polygon points="11.43 6.58 11.36 6.5 10.54 7.22 12.97 9.96 6.09 9.96 6.09 11.04 12.97 11.04 10.54 13.78 11.36 14.5 14.85 10.57 14.91 10.5 11.43 6.58" fill="white"/></svg>')
            center no-repeat;
        }
      }
    }
  }
}
