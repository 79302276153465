.childTopic {
  overflow-y: scroll;
  // section colours
  --section-colour: #{$watermelon};
  --section-colour-dark: #{$watermelon-dark};

  &.stigma {
    --section-colour: #{$watermelon};
    --section-colour-dark: #{$watermelon-dark};
  }

  &.relationships {
    --section-colour: #{$periwinkle};
    --section-colour-dark: #{$periwinkle-dark};
  }

  &.current-culture-of-dementia-care {
    --section-colour: #{$orangey-red};
    --section-colour-dark: #{$orangey-red-dark};
  }

  &.identity {
    --section-colour: #{$sickley-yellow};
    --section-colour-dark: #{$sickley-yellow-dark};
  }

  &.possibilities {
    --section-colour: #{$tiffany-blue};
    --section-colour-dark: #{$tiffany-blue-dark};
  }

  .learning-modules-top-nav {
    position: fixed;
    z-index: 15;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1.5rem 2rem;
    @include breakpoint(sm) {
      right: 0;
      left: 0;
      width: 95%;
      margin: auto;
    }
    @media only screen and (max-width: $control-width-sm) {
      width: 100%;
    }
    .close-link {
      position: absolute;
      left: rem(32);
      z-index: 1001;
      @include breakpoint(md) {
        left: rem(16);
      }
    }
  }

  .breadcrumbs-button {
    z-index: 1001;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: rem(40);
    padding-right: rem(16);
    padding-left: rem(16);
    border: 1px solid var(--section-colour);
    border-radius: 25px;

    &.relationships {
      background: #faf8ff;
    }

    &.stigma {
      background: #faf6f7;
    }

    &.stigma {
      background: #fdfdf5;
    }

    &.current-culture-of-dementia-care {
      background: #fff6f4;
    }

    &.possibilities {
      background: #f9fdfe;
    }

    h1 {
      display: inline-block;
      margin-right: rem(5);
      margin-bottom: 0;
      color: black;
      font-weight: bold;
      font-size: rem(13);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-transform: capitalize;
      @media only screen and (max-width: $control-width-sm) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    h2 {
      display: inline-block;
      margin-bottom: 0;
      color: black;
      font-weight: normal;
      font-size: rem(13);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      @include breakpoint(md) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .module-nav {
    position: absolute;
    top: 50%;
    left: 2rem;
    z-index: 15;
    transform: translateY(-50%);

    .learning-module-navigation {
      width: rem(150);
      margin: 0;
      padding: 0;

      li {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: rem(150);
        max-width: rem(150);
        height: rem(20);
        margin-bottom: 1rem;
        list-style: none;

        &.completed {
          &::before {
            opacity: 0.25;
          }
        }

        .nav-item {
          position: absolute;
          top: 50%;
          left: rem(5);
          display: inline-block;
          width: rem(150);
          padding-left: rem(12);
          font-weight: 600;
          font-size: rem(12);
          line-height: 1.3;
          transform: translateY(-50%);
          opacity: 0;
          transition: opacity 0.3s ease-in;

          &.current {
            top: 35%;
            border: none;
            opacity: 1;

            &:hover {
              border: none;

              &::after {
                border: none;
              }
            }

            &::after {
              border: none;
            }
          }
        }
        &::before {
          position: absolute;
          top: 50%;
          left: rem(0);
          display: inline;
          width: rem(6);
          height: rem(6);
          background-color: var(--section-colour);
          border-radius: 50%;
          transform: translateY(-50%);
          transition: all 0.3s ease-in;
          content: '';
        }
        &::after {
          position: absolute;
          top: 50%;
          left: rem(0);
          display: inline;
          width: rem(6);
          height: rem(6);
          border: rem(0.5) rgba(0, 0, 0, 0) solid;
          border-radius: 50%;
          transform: scale(1) translate(0, -50%);
          transform-origin: 50% 100%;
          transition: all 0.3s ease-in;
          content: '';
        }

        &:hover {
          cursor: pointer;
          &::after {
            width: rem(12);
            height: rem(12);
            border: rem(0.1) rgba(0, 0, 0, 0.2) solid;
            transform: translate(-25%, -50%);
          }
          a {
            text-decoration: none;
            opacity: 0.2;
          }
        }

        &.current {
          &::after {
            border: rem(0.1) rgba(0, 0, 0, 0) solid;
          }
          &::before {
            border: rem(0.1) rgba(0, 0, 0, 0) solid;
            transform: scale(2) translateY(-50%);
          }

          &:hover {
            &::after {
              border: rem(0.1) rgba(0, 0, 0, 0) solid;
            }
            &::before {
              border: rem(0.1) rgba(0, 0, 0, 0) solid;
            }
          }
        }
      }

      // .current {
      //   position: relative;
      //   width: rem(150);
      //   max-width: rem(150);
      //   height: rem(16);
      //   margin-bottom: 1rem;
      //   list-style: none;

      //   .nav-item {
      //     position: absolute;
      //     top: 50%;
      //     left: 0;
      //     display: inline-block;
      //     width: rem(150);
      //     padding-left: rem(12);
      //     font-weight: 600;
      //     font-size: rem(12);
      //     transform: translateY(-50%);
      //     opacity: 0;
      //     transition: opacity 0.3s ease-in;
      //   }

      //   &::before {
      //     transform: scale(2);
      //   }
      // }

      @media (max-width: rem(970)) {
        display: none;
      }
    }
    @include breakpoint(md) {
      top: inherit;
      bottom: rem(76);
    }

    @media (max-width: rem(375)) {
      top: 83vh;
    }

    @include breakpoint(sm) {
      top: inherit;
      bottom: rem(92);
      transform: translate(0, 0);
    }
  }

  .learning-modules-container {
    // position: relative;
    // z-index: -1;
    height: 100%;
    overflow-y: scroll;
    scroll-snap-type: y proximity;
    transition: 0.35s;

    // scroll-behavior: smooth;

    &::before {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
      display: block;
      width: 100vw;
      height: 100vh;
      max-height: -webkit-fill-available;
      opacity: 0;
      transition: 0.35s;
      content: '';
      pointer-events: none;
    }

    &::after {
      position: fixed;
      top: 0;
      left: 0;
      z-index: -2;
      display: block;
      width: 100vw;
      height: 100vh;
      max-height: -webkit-fill-available;
      background: #fff6f7;
      content: '';
    }

    &.bg-disabled {
      &::before {
        opacity: 0;
        transition: 0.35s;
      }
      &::after {
        opacity: 1;
      }
    }

    &.bg-active {
      &::before {
        opacity: 1;
        transition: 0.35s;
      }
      &::after {
        opacity: 1;
      }
    }

    .module {
      z-index: 10;
      scroll-snap-align: start;
      display: flex;
      flex-direction: column;
      align-items: center;

      .module-wrapper {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        max-width: 100%;
        height: 100vh;
        max-height: -webkit-fill-available;
      }

      .quote-citation {
        max-width: rem(460);
        margin-bottom: 0;
        padding-top: 0;

        p {
          margin-bottom: 0;
          color: black;
          font-weight: bold;
          font-size: rem(15);
          font-family: $serif-font-family;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.27;
          letter-spacing: normal;
          text-align: left;
        }

        a {
          color: black;
          font-weight: bold;
          font-size: rem(15);
          font-family: $serif-font-family;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.27;
          letter-spacing: normal;
          text-align: center;
          word-break: break-word;
        }
      }
    }
  }
  &.relationships {
    .learning-modules-container {
      &::before {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
          url('../../assets/textures//bg-relationships-texture.jpg') center center no-repeat;
        background-size: cover;
      }
    }
  }
  &.current-culture-of-dementia-care {
    .learning-modules-container {
      &::before {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
          url('../../assets/textures//bg-culture-texture.jpg') center center no-repeat;
        background-size: cover;
      }
    }
  }
  &.identity {
    .learning-modules-container {
      &::before {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
          url('../../assets/textures//bg-identity-texture.jpg') center center no-repeat;
        background-size: cover;
      }
    }
  }
  &.possibilities {
    .learning-modules-container {
      &::before {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
          url('../../assets/textures//bg-possibilities-texture.jpg') center center no-repeat;
        background-size: cover;
      }
    }
  }
  &.stigma {
    .learning-modules-container {
      &::before {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
          url('../../assets/textures//bg-stigma-texture.jpg') center center no-repeat;
        background-size: cover;
      }
    }
  }
}

#root {
  height: 100vh;
  height: -webkit-fill-available;

  > .container {
    top: 0;
    height: 100vh;
    max-height: -webkit-fill-available;
  }
}

.childTopic-enter {
  opacity: 0;
}

.childTopic-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.childTopic-exit {
  opacity: 1;
}
.childTopic-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.topNav-enter {
  opacity: 0;
}

.topNav-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.topNav-exit {
  opacity: 1;
}
.topNav-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.childTopic {
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow-y: hidden;
}

.intro-module-ani {
  opacity: 0;
}

.intro-module-ani-enter {
  opacity: 0;
}

.intro-module-ani-enter-active {
  opacity: 1;
  transition: opacity 1500ms;
}

.intro-module-ani-enter-done {
  opacity: 1;
}

.intro-module-ani-exit {
  opacity: 1;
}

.intro-module-ani-exit-active {
  opacity: 0;
  transition: opacity 1500ms;
}

.video-wrapper,
.quote-wrapper,
.gallery-wrapper,
.text-wrapper,
.audio-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: rem(350);
  opacity: 0;

  @include breakpoint(sm) {
    height: 100%;
  }
}

.intro-container {
  .video-wrapper {
    opacity: 1;
  }
}

.instructions {
  justify-content: flex-start;

  .intro-container-ani {
    justify-content: flex-start;

    .video-wrapper {
      justify-content: flex-start;
      height: initial;

      .video-holder {
        position: relative;
        width: 100%;
        max-width: rem(500);
        height: rem(304);
        object-fit: contain;
        background: black;

        @media (max-width: rem(1200)) and (max-height: rem(850)) {
          max-width: rem(600);
          height: rem(365);
        }

        @media (max-width: rem(600)) {
          max-width: rem(400);
          height: rem(243);
        }

        @media (max-width: rem(400)) {
          max-width: rem(300);
          height: rem(182);
        }

        .play-button {
          position: relative;
          z-index: 1000;

          .place-holder {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            box-shadow: 0 rem(30) rem(45) 0 rgba(128, 131, 142, 0.4);
          }
          .overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(128, 131, 142, 0.4);
          }
        }
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 999;
          width: 100%;
          height: 100%;
          box-shadow: 0 rem(30) rem(45) 0 rgba(128, 131, 142, 0.4);
        }
      }
      .play-button {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        cursor: pointer;

        .video-play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          width: rem(80);
          height: rem(80);
          padding-left: rem(5);
          color: white;
          font-size: rem(32);
          line-height: rem(80);
          background: var(--section-colour);
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }
        &.hide-placeholder {
          visibility: hidden;
        }
      }
    }
  }
}

.intro-controls.instructions {
  justify-content: center;
  width: initial;
  max-width: 100%;
  margin-top: rem(50);
}

.standard-content-container {
  .video-wrapper {
    justify-content: flex-start;
    height: initial;
    margin-top: rem(50);
    opacity: 1;

    .video-holder {
      position: relative;
      width: 100%;
      max-width: rem(820);
      height: rem(500);
      object-fit: cover;
      background: black;

      @media (max-width: rem(1200)) and (max-height: rem(850)) {
        max-width: rem(720);
        height: rem(440);
      }

      @media (max-height: rem(800)) {
        max-width: rem(605);
        height: rem(369);
      }

      @media (max-height: rem(600)) {
        max-width: rem(410);
        height: rem(250);
      }

      .play-button {
        position: relative;
        z-index: 1000;
        width: 100%;
        height: 100%;

        .place-holder {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          box-shadow: 0 rem(30) rem(45) 0 rgba(128, 131, 142, 0.4);
        }
        .overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(128, 131, 142, 0.4);
        }
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 100%;
        box-shadow: 0 rem(30) rem(45) 0 rgba(128, 131, 142, 0.4);
      }
    }
    .play-button {
      position: relative;
      display: block;
      background: transparent;
      border: none;
      cursor: pointer;

      .video-play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: rem(80);
        height: rem(80);
        padding-left: rem(5);
        color: white;
        font-size: rem(32);
        line-height: rem(80);
        background: var(--section-colour);
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
      &.hide-placeholder {
        visibility: hidden;
      }
    }
  }
}

.video-wrapper {
  height: rem(550);

  @include breakpoint(sm) {
    height: 100%;
  }
}

.gallery-wrapper {
  @include breakpoint(sm) {
    justify-content: center;
  }
}

.learning-module-ani-enter {
  transform: translateY(5%);
  opacity: 0;
}
.learning-module-ani-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: ease-in 1000ms;
}
.learning-module-ani-enter-done {
  opacity: 1;
}
.learning-module-ani-exit {
  opacity: 1;
}
.learning-module-ani-exit-active {
  opacity: 0;
  transition: ease-in 1000ms;
}
.learning-module-ani-exit-done {
  opacity: 0;
}

// Text & Qupte button styles

.button-container {
  position: absolute;
  top: 50%;
  right: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);

  @media only screen and (max-width: $size-md) {
    right: rem(45);
  }
  @media only screen and (max-width: $size-sm) {
    top: 110%;
    right: rem(-10);
    bottom: 3.75rem;
    display: block;
    flex-direction: row;
    width: 100%;
  }
}

.quote-wrapper {
  .button-container {
    top: 40%;
    transform: translateY(-50%);

    @include breakpoint(sm) {
      top: initial;
      right: rem(19);
      bottom: rem(60);
      z-index: 1000;
      display: flex;
      width: auto;
    }

    button:first-of-type {
      margin-bottom: rem(10);
    }

    > button {
      @include breakpoint(sm) {
        margin-left: rem(12);
      }
    }
  }
}

.text-wrapper {
  .button-container {
    @include breakpoint(sm) {
      top: initial;
      right: rem(19);
      bottom: rem(60);
      z-index: 1000;
      display: flex;
      width: auto;
    }

    > button {
      @include breakpoint(sm) {
        margin-left: rem(12);
      }
    }
  }
}

.gallery-wrapper {
  .button-container {
    @include breakpoint(sm) {
      top: initial;
      right: rem(19);
      bottom: rem(60);
      z-index: 1000;
      display: flex;
      width: auto;
    }

    > button {
      @include breakpoint(sm) {
        margin-left: rem(12);
      }
    }
  }
}

.video-wrapper {
  .button-container {
    @include breakpoint(lg) {
      top: 50%;
      right: rem(19);
      transform: translateY(34.5vh);
    }

    @include breakpoint(sm) {
      top: initial;
      right: rem(19);
      bottom: rem(86);
      z-index: 1000;
      display: flex;
      width: auto;
      transform: translateY(0);
    }

    // @media only screen and (max-width: $size-sm) and (max-height: rem(900)) {
    //   top: 94%;
    // }

    // @media only screen and (max-width: $size-sm) and (max-height: rem(800)) {
    //   top: 100%;
    // }

    // @media only screen and (max-width: $size-sm) and (max-height: rem(700)) {
    //   top: 94%;
    // }

    // @media only screen and (max-width: $size-sm) and (max-height: rem(600)) {
    //   top: 84%;
    // }
  }
}

.container_animation {
  .button-container {
    @include breakpoint(sm) {
      top: 80.5vh;
      right: rem(19);
      z-index: 1000;
      display: flex;
      width: auto;
    }

    @include breakpoint(xs) {
      top: 72.5vh;
      transform: translateY(0);
    }
  }
}

.audio-wrapper {
  .button-container {
    @include breakpoint(sm) {
      top: 80%;
      right: -1rem;
    }
  }
}

.button-controls {
  display: block;
  width: rem(40);
  height: rem(40);
  margin-bottom: rem(10);
  background: var(--section-colour);
  border-radius: 50%;

  .pause_btn {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M19,30h4V18H19Zm6-12V30h4V18Z" fill="white"/></svg>')
      no-repeat;
  }
  .play_btn {
    background: none;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.2 12"><polygon points="0 12 0 0 10.2 6 0 12" fill="white"/></svg>')
      center center no-repeat;
    background-position-x: 55%;
    background-size: rem(12);
  }
  .pause_btn,
  .play_btn {
    overflow: hidden;
    text-indent: rem(-999);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--section-colour-dark);
    }
  }

  &:disabled {
    background: lightgray;
  }
  &:disabled:hover {
    background: lightgray;
  }

  .arrow {
    width: rem(40);
    height: rem(40);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><circle cx="20" fill="None" cy="20" r="20"/><polygon points="18.85 26.15 17 24.3 21.3 20 17 15.7 18.85 13.85 25 20 18.85 26.15" fill="%23FFF"/></svg>');
  }
  .play-btn,
  .pause-btn {
    img {
      width: rem(40);
      vertical-align: middle;
    }
  }
}

.prev {
  .arrow {
    transform: rotate(180deg);
  }
}

.button-controls.play {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 485 485" style="enable-background:new 0 0 485 485;" xml:space="preserve"><path d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z" fill="black"/><polygon points="181.062,336.575 343.938,242.5 181.062,148.425" fill="black"/></svg>')
    center center no-repeat;
}

.button-controls.pause {
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 271.953 271.953" style="enable-background:new 0 0 271.953 271.953;" xml:space="preserve" fill="darkgrey"><path d="M135.977,271.953c75.097,0,135.977-60.879,135.977-135.977S211.074,0,135.977,0S0,60.879,0,135.977S60.879,271.953,135.977,271.953z M135.977,21.756c62.979,0,114.22,51.241,114.22,114.22s-51.241,114.22-114.22,114.22s-114.22-51.241-114.22-114.22S72.992,21.756,135.977,21.756z" fill="black"/><path d="M110.707,200.114c7.511,0,13.598-6.086,13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598c-7.511,0-13.598,6.086-13.598,13.598v103.342C97.109,194.028,103.195,200.114,110.707,200.114z" fill="black"/><path d="M165.097,200.114c7.511,0,13.598-6.086,13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598S151.5,75.663,151.5,83.174v103.342C151.5,194.028,157.586,200.114,165.097,200.114z" fill="black"/></svg>')
    center center no-repeat;
}

.child-browser-container {
  width: 100%;
  height: 100%;
}

.opacity-mask {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: rem(100);
  opacity: 1;
  transition: 0.3s;
}
.relationships {
  .opacity-mask {
    background: linear-gradient(rgba(250, 248, 255, 0.9), rgba(250, 248, 255, 0));
  }
}
.stigma {
  .opacity-mask {
    background: linear-gradient(rgba(255, 246, 247, 0.9), rgba(255, 246, 247, 0));
  }
}
.identity {
  .opacity-mask {
    background: linear-gradient(rgba(253, 253, 245, 0.9), rgba(253, 253, 245, 0));
  }
}
.current-culture-of-dementia-care {
  .opacity-mask {
    background: linear-gradient(rgba(255, 246, 244, 0.9), rgba(255, 246, 244, 0));
  }
}
.possibilities {
  .opacity-mask {
    background: linear-gradient(rgba(249, 253, 254, 0.9), rgba(249, 253, 254, 0));
  }
}

.backgroundActive {
  .opacity-mask {
    opacity: 0;
  }
}
