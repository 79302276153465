.auth-loading-spinner {
  .spinner {
    z-index: 2;
    width: rem(25);
    height: rem(25);
    margin: 0 auto;
    vertical-align: bottom;
    animation: rotate 2s linear infinite;

    & .path {
      animation: dash 1.5s ease-in-out infinite;
      stroke: rgb(0, 0, 0);
      stroke-linecap: round;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}
