body {
  position: relative;
  color: $bg-color-dark;
  background-color: var(--color-page-background);
}

.page {
  position: relative;
  z-index: 4;
  color: var(--color-content-text);
}

#root {
  position: relative;
  height: 100vh;
  height: -webkit-fill-available;

  > .main-container {
    position: relative;
    top: 0;
    height: 100vh;
    max-height: -webkit-fill-available;
    padding-right: 0;
    padding-left: 0;
    color: $white;
    background: linear-gradient(#000, #04082f);

    .page.topics,
    .page.dashboard {
      color: $black;
      background: #fff6f7;
    }

    .page.topics {
      @include breakpoint(sm) {
        overflow-y: hidden;
      }
    }

    .page.relationships {
      background: #faf8ff;
    }
    .page.stigma {
      background: #faf6f7;
    }
    .page.identity {
      background: #fdfdf5;
    }
    .page.current-culture-of-dementia-care {
      background: #fff6f4;
    }
    .page.possibilities {
      background: #f9fdfe;
    }

    .page.topics {
      .relationships {
        background: #faf8ff;
      }
      .stigma {
        background: #faf6f7;
      }
      .identity {
        background: #fdfdf5;
      }
      .current-culture-of-dementia-care {
        background: #fff6f4;
        @include breakpoint(sm) {
          max-width: 100%;
        }
      }
      .possibilities {
        background: #f9fdfe;
      }
    }
  }
}
