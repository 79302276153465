/* Not sure if any of this is used START */

.account-privacy {
  button {
    color: white;
    &:hover {
      text-decoration: underline;
    }
  }
}

.modal-panel__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.7);
}

.modal-pane {
  left: 25vw;
  display: flex;
  flex-direction: column;
  height: 97%;
  overflow: hidden;
  transition: transform 0.75s;
  will-change: transform;

  @media screen and (max-width: $size-lg) {
    left: 10%;
  }

  @media screen and (max-width: $size-sm) {
    left: 5%;
  }
}

.cta {
  span {
    margin-right: rem(12);
  }

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

// Hide bandwidth checker
// main > p {
//   display: none;
// }

.bandwidth-warning {
  position: absolute;
  right: rem(17);
  bottom: rem(17);
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: rem(296);
  height: rem(54);
  padding: rem(7) rem(12) rem(13) rem(13);
  background-color: black;
  border-radius: rem(6);
  box-shadow: 0 rem(2) rem(4) 0 rgba(0, 0, 0, 0.25);
  @media screen and (max-width: $size-sm) {
    right: 50%;
    transform: translateX(50%);
  }

  .speed-image {
    width: rem(30);
    height: rem(22);
    margin: rem(8) 0 rem(4) 0;
    padding: rem(4) rem(13) rem(7) rem(14);
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="20.63" viewBox="0 0 28 20.63"><path fill="white" d="M14,2A17.67,17.67,0,0,1,25.12,5.9L14,17.72,2.88,5.9A17.67,17.67,0,0,1,14,2m0-2A20,20,0,0,0,0,5.76L14,20.63,28,5.76A20,20,0,0,0,14,0Z"/><path d="M12.68,14.22h2.64V11.58H12.68Zm0-10.64v7h2.64v-7Z" fill="white" fill-rule="evenodd"/></svg>')
      center no-repeat;
  }

  p {
    width: rem(203);
    height: rem(17);
    margin: 0 rem(16) 0 rem(12);
    color: var(--white);
    font-weight: normal;
    font-size: rem(12);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: rem(0.25);
  }

  .activate {
    width: rem(191);
    height: rem(17);
    margin: rem(2) rem(28) 0 rem(12);
    color: var(--white);
    font-weight: normal;
    font-size: rem(12);
    font-family: $base-font-family;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: rem(0.25);

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .close-container {
    height: 100%;

    .close {
      width: rem(10);
      height: rem(10);
      margin: rem(5) 0 rem(2) 0;
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="11.1" height="11.1" viewBox="0 0 11.1 11.1"><polygon fill="white" points="11.1 1.4 9.7 0 5.6 4.1 1.4 0 0 1.4 4.1 5.6 0 9.7 1.4 11.1 5.6 7 9.7 11.1 11.1 9.7 7 5.6 11.1 1.4"/></svg>')
        center no-repeat;
    }
  }
}

.bandwidth-warning-enter {
  opacity: 0;
}
.bandwidth-warning-enter-active {
  opacity: 1;
  transition: opacity 900ms;
}
.bandwidth-warning-exit {
  opacity: 1;
}
.bandwidth-warning-exit-active {
  opacity: 0;
  transition: opacity 900ms;
}

/* Not sure if any of this is used END */
