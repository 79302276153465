// Typography
h2,
h3,
h4 {
  font-weight: 800;
}

h1 {
  font-weight: 600;
  font-size: rem(31);
  line-height: rem(36);
  letter-spacing: rem(-1);

  @media (min-width: $size-md) {
    font-size: rem(40);
    line-height: rem(46);
  }
}

h2 {
  font-size: rem(20);
  line-height: rem(26);
  letter-spacing: rem(-0.25);

  @media (min-width: $size-md) {
    font-size: rem(24);
    line-height: rem(30);
  }
}

h3 {
  font-size: rem(16);
  line-height: rem(22);
  letter-spacing: rem(-0.25);

  @media (min-width: $size-md) {
    font-size: rem(18);
    line-height: rem(24);
  }
}

h4 {
  font-size: rem(13);
  line-height: rem(18);
}

// Numbers
.numeral-bold {
  font-weight: 600;
  font-size: rem(45);
  line-height: rem(50);
}

.numeral-light {
  font-weight: 200;
  font-size: rem(45);
  line-height: rem(50);
}

.serif-text {
  font-family: $serif-font-family;
}

// Paragraphs
.text-xl {
  font-size: rem(26);
  line-height: rem(29);

  @media (min-width: $size-md) {
    font-size: rem(34);
    line-height: normal;
  }
}

.text-lg {
  font-size: rem(18);
  line-height: rem(23);

  @media (min-width: $size-md) {
    font-size: rem(24);
    line-height: normal;
  }
}

.text-md {
  font-size: rem(18);
  line-height: normal;

  @media (min-width: $size-md) {
    font-size: rem(20);
    line-height: normal;
  }
}

.text-sm {
  font-size: rem(15);
  line-height: rem(19);

  @media (min-width: $size-md) {
    font-size: rem(18);
    line-height: rem(24);
  }
}

.text-xs {
  font-size: rem(14);
  line-height: rem(17);

  @media (min-width: $size-md) {
    font-size: rem(15);
    line-height: rem(19);
  }
}

// Description
.description {
  font-size: rem(13);
  font-family: $body-font-family;
  line-height: normal;
}

// Legal
.legal {
  color: $steel;
  font-size: rem(10);
  font-family: $body-font-family;
  line-height: normal;
}

// Quote
.quote {
  font-size: rem(65);
  font-family: $quote-font-family;
  line-height: rem(44);

  @media (min-width: $size-md) {
    font-size: rem(114);
    line-height: rem(78);
  }
}

// Navigation
.menu-nav-item,
.menu-nav-item-secondary,
.module-nav-item {
  font-family: $body-font-family;
}

.menu-nav-item {
  font-size: rem(18);
  line-height: rem(24);
}

.menu-nav-item-secondary {
  font-size: rem(12);
  line-height: normal;
}

.module-nav-item {
  font-weight: 600;
  font-size: rem(11);
  line-height: rem(14);
}

// Topics
.topic-nav-parent,
.topic-nav-child {
  font-family: $body-font-family;
}

.topic-nav-parent {
  font-weight: 600;
  font-size: rem(13);
  line-height: rem(18);
}

.topic-nav-child {
  font-weight: normal;
  font-size: rem(11);
  line-height: normal;
}

// Form elements
.form-field-input,
.form-field-input__placeholder {
  font-family: $body-font-family;
}

.form-field-input {
  font-size: rem(14);
}

.form-field-input__placeholder {
  color: $steel;
  font-weight: 400;
  font-size: rem(14);
  opacity: 0.5;
}

// Pagination
.pagination {
  font-size: rem(10);
  font-family: $body-font-family;
}
