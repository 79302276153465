.link-back {
  position: absolute;
  display: flex;
  width: 100%;
  padding: rem(40);
  //background-color: red;
  @media (max-width: $size-md) {
    padding: rem(15);
  }
  a {
    color: white;
  }
}

.login,
.register,
.forgot-password {
  background-repeat: no-repeat;
  background-position: bottom;
}

.container-auth {
  width: 100%;
  min-width: rem(320);
  max-width: rem(500);
  height: 100%;
  padding: rem(50) rem(20) rem(20) rem(20);
  overflow: hidden;
  color: $secondary-color;

  form {
    z-index: 10;
  }

  h1 {
    margin-top: 0;
    font-size: rem(20);

    @media (min-width: $size-sm) {
      font-size: rem(24);
    }
  }

  .hide-sm {
    display: inline-flex;
    @media (max-width: $size-md) {
      display: none;
    }
  }

  &.container-intro-auth {
    max-width: rem(840);

    .columns {
      justify-content: center;
    }

    .column:first-child,
    .column:last-child {
      max-width: rem(250);
    }

    p {
      margin-bottom: rem(22);
      color: $bluey-grey;
    }

    .btn {
      font-weight: 600;
    }

    .btn-continue {
      color: $slate-grey;
      background-color: $secondary-color;
      border: 1px solid $secondary-color;
    }

    .btn-signup {
      color: $slate-grey;
      background-color: transparent;
      border: 1px solid $border-color;
    }

    @media (min-width: $size-xs) {
      p {
        margin-bottom: rem(35);
      }

      .columns {
        justify-content: space-between;
      }

      .column:first-child,
      .column:last-child {
        max-width: rem(300);
      }
    }
  }

  // Remove h2 font sizes here when style guide is up
  h2 {
    margin-bottom: rem(22);
    font-size: rem(20);

    @media (min-width: $size-md) {
      font-size: rem(24);
    }
  }

  .google,
  .facebook {
    z-index: 10;
    margin: 0 auto;
    text-transform: capitalize;
  }

  .auth-link {
    position: relative;
    z-index: 10;
    margin-top: 1rem;

    a,
    button {
      padding: 0;
      color: $secondary-color;
      text-decoration: underline;
    }
  }

  .divider {
    margin: 1rem 0;
    border-color: #cbcbcb;
  }

  .divider-vert[data-content]::after {
    background: none;
  }

  .divider-text {
    z-index: 10;
    display: flex;
    flex-direction: row;
    margin-bottom: rem(12);

    &::before,
    &::after {
      display: inline-block;
      flex-grow: 2;
      width: auto;
      height: 0.7rem;
      border-bottom: 1px solid white;
      opacity: 0.35;
      content: '';
    }

    span {
      width: auto;
      margin: 0 0.5rem;
    }
  }

  .divider-text-vertical {
    display: flex;
    flex-direction: row;
    flex-grow: 0.5;
    padding: rem(25) 0;

    &::before,
    &::after {
      flex-grow: 2;
      align-self: center;
      width: rem(1);
      height: auto;
      border-top: 1px solid $border-color;
      content: '';
    }

    span {
      margin: 0 0.5rem;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    @media (max-width: $size-xxs) {
      width: 100%;
    }

    li {
      display: inline-block;
      width: 49%;
      @media (max-width: $size-xxs) {
        width: 100%;
      }

      button {
        @media (max-width: $size-xxs) {
          width: 100%;
        }
      }
    }
  }

  .btn-icon {
    display: inline-block;
    width: 100%;
  }

  @media (max-width: $size-xxs) {
    overflow: scroll;
  }

  @media (min-width: $size-xs) {
    overflow: none;

    .divider-text-vertical {
      flex-direction: column;
      max-width: rem(183);
      padding: 0;

      &::before,
      &::after {
        width: auto;
        height: rem(1);
        border-right: 1px solid $border-color;
      }

      span {
        margin: 0.5rem 0;
      }
    }

    h1 {
      margin-top: rem(20);
    }
  }

  @media (min-width: $size-sm) {
    padding: rem(100) 0 0 0;
    overflow: unset;

    h1 {
      margin-top: 0;
    }
  }
}

.register-container {
  padding: 0, rem(20);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $size-xs) {
    padding: rem(100), 0, 0, 0;
  }
}

.login-container {
  @media (max-width: $size-xxs) {
    padding: rem(250) rem(20) rem(20) rem(20);
    overflow: scroll;
  }

  form {
    z-index: 100;
  }
}

.forgot-password {
  h1 {
    margin-top: 0;
  }
}

.intro-component-ani-enter {
  opacity: 0;
}
.intro-component-ani-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.intro-component-ani-exit {
  opacity: 1;
}
.intro-component-ani-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.auth-button-container {
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: $size-xxs) {
      flex-direction: column;
    }
  }
}
