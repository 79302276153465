.user-controls {
  position: absolute;
  bottom: rem(17);
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100vw;
  max-height: rem(50);
  padding: 0 rem(40);
  opacity: 0;
  pointer-events: none;
  &.show {
    // z-index: 1000;
    opacity: 1;
    transition: opacity 1.5s ease-in-out;
  }
  &.hide {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  @include breakpoint(lg) {
    padding: 0 rem(20);
  }
  @include breakpoint(sm) {
    // justify-content: flex-end;
    padding-left: rem(2);
  }

  .bottom-logo {
    width: rem(135);
    height: rem(37);
    margin-bottom: rem(27);
    pointer-events: auto;
    @include breakpoint(sm) {
      // display: none;
      transform: scale(0.8);
    }

    /* prettier-ignore */
    .logo-icon {
      width: rem(135);
      height: rem(37);
      transition: 0.5s;
    }
  }

  .controller {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: rem(114);
    height: rem(140);
    margin-bottom: rem(25);
    pointer-events: auto;

    @include breakpoint(md) {
      flex-direction: row;
      height: fit-content;
    }

    .controller-icon {
      display: inline-block;
      width: rem(20);
      height: rem(16);
      margin-top: rem(4);
      margin-right: rem(4);
      transform: rotate(90deg);
    }

    .controller-icon.play {
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 485 485" style="enable-background:new 0 0 485 485;" xml:space="preserve"><path d="M413.974,71.026C368.171,25.225,307.274,0,242.5,0S116.829,25.225,71.026,71.026C25.225,116.829,0,177.726,0,242.5s25.225,125.671,71.026,171.474C116.829,459.775,177.726,485,242.5,485s125.671-25.225,171.474-71.026C459.775,368.171,485,307.274,485,242.5S459.775,116.829,413.974,71.026z M242.5,455C125.327,455,30,359.673,30,242.5S125.327,30,242.5,30S455,125.327,455,242.5S359.673,455,242.5,455z" fill="black"/><polygon points="181.062,336.575 343.938,242.5 181.062,148.425" fill="black"/></svg>')
        center center no-repeat;
    }

    .controller-icon.pause {
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 271.953 271.953" style="enable-background:new 0 0 271.953 271.953;" xml:space="preserve" fill="darkgrey"><path d="M135.977,271.953c75.097,0,135.977-60.879,135.977-135.977S211.074,0,135.977,0S0,60.879,0,135.977S60.879,271.953,135.977,271.953z M135.977,21.756c62.979,0,114.22,51.241,114.22,114.22s-51.241,114.22-114.22,114.22s-114.22-51.241-114.22-114.22S72.992,21.756,135.977,21.756z" fill="black"/><path d="M110.707,200.114c7.511,0,13.598-6.086,13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598c-7.511,0-13.598,6.086-13.598,13.598v103.342C97.109,194.028,103.195,200.114,110.707,200.114z" fill="black"/><path d="M165.097,200.114c7.511,0,13.598-6.086,13.598-13.598V83.174c0-7.511-6.086-13.598-13.598-13.598S151.5,75.663,151.5,83.174v103.342C151.5,194.028,157.586,200.114,165.097,200.114z" fill="black"/></svg>')
        center center no-repeat;
    }

    .sound-icon {
      display: inline-block;
      width: rem(16);
      height: rem(12);
      margin-left: rem(0);
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 22.6 16.8"><path d="M11.76,15.33V1.52L0,8.43ZM15.19,3.05a8,8,0,0,1,0,10.7l.89.8a9.22,9.22,0,0,0,0-12.3ZM19.4,0l-.9.8a11.41,11.41,0,0,1,0,15.2l.9.8A12.62,12.62,0,0,0,19.4,0Z" fill="black"/></svg>')
        center center no-repeat;
    }

    .volume-slider.hide-sound {
      visibility: hidden;
    }

    .volume-slider {
      position: absolute;
      right: rem(-10);
      bottom: rem(120);
      display: flex;
      align-self: flex-end;
      padding: rem(5);
      background: white;
      border-radius: rem(25);
      box-shadow: 0 rem(2) rem(4) 0 rgba(128, 131, 142, 0.15);
      transform: rotate(270deg);

      input[type='range'] {
        width: rem(80);
      }

      .sound-icon {
        transform: rotate(90deg);
      }

      .mute-icon {
        display: inline-block;
        width: rem(16);
        height: rem(16);
        margin-left: rem(4);
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 22.6 13.81"><path d="M11.76,13.81V0L0,6.9Z" fill="black"/><polygon points="21.55 2.79 22.6 3.84 19.54 6.91 22.6 9.97 21.55 11.01 18.49 7.95 15.43 11.01 14.38 9.97 17.45 6.91 14.38 3.84 15.43 2.79 18.49 5.86 21.55 2.79" fill="black"/></svg>')
          center center no-repeat;
        transform: rotate(90deg);

        @include breakpoint(md) {
          margin-top: rem(4);
        }
      }
    }

    .control {
      &:nth-last-of-type(-n + 3) {
        @include breakpoint(md) {
          display: none;
        }
      }

      &.hide {
        display: none;
      }
    }
  }

  .controls.controls-volume-small {
    display: none;
    margin-left: rem(14);
    @include breakpoint(md) {
      display: flex;
      align-items: center;
      justify-content: center;

      .control {
        display: block;
      }
    }
  }

  .controls {
    padding: rem(11) 0 rem(8) 0;
    background-color: white;
    border-radius: rem(20);
    box-shadow: 0 rem(2) rem(4) 0 rgba(128, 131, 142, 0.15);

    @include breakpoint(md) {
      width: rem(38);
      height: rem(38);
      padding: rem(11) 0 rem(11) 0;

      .sound-icon {
        margin-top: rem(4);
      }
    }

    .control {
      position: relative;
      display: inline-block;
      width: rem(38);
      text-align: center;

      /* prettier-ignore */
      .info-icon {
        display: inline-block;
        width: rem(16);
        height: rem(16);
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22"><path d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,11,20Zm-1-4h2V10H10Zm0-8h2V6H10Z"/></svg>') center center no-repeat;
      }

      .profile-icon {
        display: inline-block;
        width: rem(16);
        height: rem(16);
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 22"><path d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,11,20Zm-1-4h2V10H10Zm0-8h2V6H10Z"/></svg>')
          center center no-repeat;
      }

      .sound-icon {
        display: inline-block;
        width: rem(16);
        height: rem(16);
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22.6 16.8"><path d="M11.76,15.33V1.52L0,8.43ZM15.19,3.05a8,8,0,0,1,0,10.7l.89.8a9.22,9.22,0,0,0,0-12.3ZM19.4,0l-.9.8a11.41,11.41,0,0,1,0,15.2l.9.8A12.62,12.62,0,0,0,19.4,0Z"/></svg>')
          center center no-repeat;
      }

      .mute-icon {
        display: inline-block;
        width: rem(16);
        height: rem(16);
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 22.6 13.81"><path d="M11.76,13.81V0L0,6.9Z" fill="black"/><polygon points="21.55 2.79 22.6 3.84 19.54 6.91 22.6 9.97 21.55 11.01 18.49 7.95 15.43 11.01 14.38 9.97 17.45 6.91 14.38 3.84 15.43 2.79 18.49 5.86 21.55 2.79" fill="black"/></svg>')
          center center no-repeat;
        @include breakpoint(md) {
          margin-top: rem(4);
        }
      }

      &::after {
        position: absolute;
        top: rem(-2);
        right: 0;
        width: rem(1);
        height: rem(20);
        border: solid rem(1) #9b9da6;
        opacity: 0.25;
        content: '';

        @include breakpoint(md) {
          display: none;
        }
      }

      &:first-of-type {
        &::after {
          top: 0;
        }
      }

      &:last-of-type {
        &::after {
          border: none;
        }
      }
    }

    .info-popup {
      position: absolute;
      right: rem(63);
      bottom: rem(90);
      width: rem(270);
      height: auto;
      padding: rem(16);
      color: white;
      background-color: black;

      @media only screen and (max-width: $size-lg) {
        right: rem(45);
      }

      p {
        margin: 0;
      }

      a {
        display: inline-block;
        margin: 0;
        padding-right: rem(12);
        padding-left: rem(12);
        border: solid 1px rgba(255, 255, 255, 0.35);

        span {
          margin: 0;
        }
      }
    }

    .info-popup.popup-hidden {
      display: none;
    }

    .info-popup::after {
      position: absolute;
      right: 7%;
      bottom: rem(-40);
      width: 0;
      height: 0;
      margin-bottom: rem(25);
      margin-left: rem(20);
      border: rem(15) solid transparent;
      border-top-color: black;
      border-bottom: 0;
      content: '';
    }
  }
}
