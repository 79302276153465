.button-controls.citation-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  .citation-icon {
    display: inline-block;
    width: rem(18);
    height: rem(18);
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 22 22"><path fill="white" d="M11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,11,20Zm-1-4h2V10H10Zm0-8h2V6H10Z"/></svg>')
      bottom center no-repeat;
    transition: 0.3s;
  }

  &::after {
    position: absolute;
    top: 10;
    left: rem(-30);
    display: none;
    width: rem(20);
    height: rem(20);
    background: white;
    content: '';
    clip-path: polygon(50% 0%, 50% 100%, 100% 50%);

    @include breakpoint(sm) {
      top: rem(-20);
      left: rem(10);
      clip-path: polygon(48% 64%, 0 0, 100% 0);
    }
  }

  &.active {
    background: lightgray;
    &::after {
      display: block;
    }
  }
}

.video-wrapper {
  .button-controls.citation-button {
    &::after {
      @include breakpoint(md) {
        top: rem(-20);
        left: rem(10);
        clip-path: polygon(48% 64%, 0 0, 100% 0);
      }
    }
  }
}

.citation-box {
  position: absolute;
  top: rem(-10);
  left: rem(-290);
  display: none;
  width: rem(272);
  height: fit-content;
  padding: rem(19);
  background: white;
  border-radius: rem(5);
  box-shadow: 0 rem(2) rem(4) 0 rgba(128, 131, 142, 0.15);
  cursor: default;

  &.show {
    display: block;
  }

  @include breakpoint(sm) {
    top: inherit;
    right: rem(0);
    bottom: rem(60);
    left: inherit;
  }
}

.video-wrapper {
  .citation-box {
    @include breakpoint(md) {
      left: inherit;
    }
  }
}
