.child-topic-completion {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .completion-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-height: -webkit-fill-available;
    scroll-snap-align: start;

    .parent-name {
      margin-top: rem(-100);
      margin-bottom: rem(10);
      color: white;
      font-weight: bold;
      font-size: rem(24);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: rem(-0.25);
      text-align: center;
    }

    .child-scroll-instructions {
      position: absolute;
      bottom: rem(50);
      left: 50%;
      z-index: 100;
      color: white;
      transform: translate(-50%, 0);
      cursor: pointer;

      @include breakpoint(sm) {
        display: none;
      }

      &.child-scroll-mobile {
        display: none;

        @include breakpoint(sm) {
          bottom: rem(80);
          display: block;
        }

        .scroll-image {
          background-image: url('../../assets/dark/swipe.svg');
          background-repeat: no-repeat;
          background-position: center;
          filter: invert(1);
        }
      }
    }
  }

  .completion-wheel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .completion-numbers {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);

    .divisor {
      margin-bottom: rem(8);
      color: white;
      font-weight: 600;
      font-size: rem(45);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      text-align: center;
    }

    .description {
      margin-bottom: 0;
      color: white;
      font-weight: normal;
      font-size: rem(12);
      font-family: $base-font-family;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: rem(0.25);
      text-align: center;
    }
  }

  .progress-ring__circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0.35s;
  }

  .progress-ring__empty-circle {
    opacity: 0.1;
  }
}
